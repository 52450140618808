<template>
    <div class="text-lg pt-[100px] pl-[100px] pr-[30px] w-[280px] h-screen">
        <div class="-mt-16 mb-6 -ml-14 w-fit flex cursor-pointer rounded-lg px-4 py-1 hover:text-sky-600"
            @click="$router.push('/')">
            <v-img :src="require('@/assets/logo.svg')" class="h-7 w-7 mr-2 my-auto cursor-pointer" contain></v-img>
            <div class="text-2xl font-medium">Streamlingo</div>
        </div>
        <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/dashboard' }"
            @click="!liveTranslationActive && $router.push('/dashboard')">
            {{ $t('sidebar.dashboard') }} 
        </div>
        <!-- <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/further-functions' }"
            @click="!liveTranslationActive && $router.push('/further-functions')">
            Erweiterungen
        </div> -->
        <div class="text-lg hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/settings', 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && $router.push('/settings')">
            {{ $t('sidebar.settings') }}
        </div>
        <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/usage', 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && $router.push('/usage')">
            {{ $t('sidebar.usage') }}
        </div>
        <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/pricing', 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && $router.push('/pricing')" v-if="!$store.state.uid">
            {{ $t('sidebar.planAndPrices') }}
        </div>
        <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/faq', 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && $router.push('/faq')" v-if="!$store.state.uid">
            {{ $t('sidebar.faq') }}
        </div>
        <div class="hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 rounded-xl"
            :class="{ 'text-indigo-600 cursor-pointer': $route.path === '/feedback', 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && $router.push('/feedback')" v-if="!$store.state.uid">
            {{ $t('sidebar.feedback') }}
        </div>
        <div class="text-sky-600 logout hover:cursor-pointer hover:bg-slate-200 pl-4 mb-2 mt-8 rounded-xl"
            :class="{ 'cursor-pointer': !liveTranslationActive, 'text-gray-400 cursor-default': liveTranslationActive }"
            @click="!liveTranslationActive && logout()">
            {{ $t('sidebar.logout') }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        liveTranslationActive() {
            return this.$store.state.liveTranslationActive;
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch('signOut');
            this.$router.push('/');
        },
    },
};
</script>