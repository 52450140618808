<template>
    <div class="w-1/2 mx-auto grid grid-cols-1">
        <div class="text-xl text-bold ma-5 mt-16 flex justify-between">
            <span class="text-2xl mx-auto font-bold">{{ $t('faq.faq') }}</span>
        </div>
        <div class="mt-8">
            <div @click="isOpen.section1 = !isOpen.section1"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section1 }">
                <span> {{ $t('faq.question1') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section1 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section1">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser1')"></div>
            </div>
            <div @click="isOpen.section6 = !isOpen.section6"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section6 }">
                <span> {{ $t('faq.question2') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section6 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section6">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser2')"></div>
            </div>
            <div @click="isOpen.section5 = !isOpen.section5"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section5 }">
                <span> {{ $t('faq.question3') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section5 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section5">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser3')"></div>
            </div>
            <div @click="isOpen.section7 = !isOpen.section7"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section7 }">
                <span> {{ $t('faq.question4') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section7 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section7">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser4')"></div>
            </div>
            <div @click="isOpen.section8 = !isOpen.section8"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section8 }">
                <span> {{ $t('faq.question5') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section8 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section8">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser5')"></div>
            </div>
            <div @click="isOpen.section2 = !isOpen.section2"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section2 }">
                <span>{{ $t('faq.question6') }} </span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section2 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section2">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser6')"></div>
            </div>
            <div @click="isOpen.section3 = !isOpen.section3"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section3 }">
                <span> {{ $t('faq.question7') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section3 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section3">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser7')"></div>
            </div>
            <div @click="isOpen.section4 = !isOpen.section4"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section4 }">
                <span> {{ $t('faq.question8') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section4 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section4">
                <div class="p-5 border-b border-gray-200" v-html="$t('faq.awnser8')"></div>
            </div>
            <div @click="isOpen.section9 = !isOpen.section9"
                class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border-b borderdark-gray-200 gap-3"
                :class="{ 'text-black': isOpen.section9 }">
                <span> {{ $t('faq.question9') }}</span>
                <svg class="w-3 h-3 shrink-0" :class="isOpen.section9 ? 'rotate-0' : 'rotate-180'"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5 5 1 1 5" />
                </svg>
            </div>
            <div v-if="isOpen.section9">
                <div class="p-5 border-b border-gray-200">
                    {{ $t('faq.awnser9_1') }}
                    <span class="text-sky-600 underline">support@streamlingo.de</span>
                    {{ $t('faq.awnser9_2') }}
                </div>
            </div>
        </div>
        <div class="mt-8 mx-auto">
            <div class="flex cursor-pointer rounded-lg px-4 py-1 hover:text-sky-600" @click="$router.push('/')">
                <v-img :src="require('@/assets/logo.svg')" class="h-7 w-7 mr-3 my-auto cursor-pointer" contain></v-img>
                <div class="text-3xl font-medium">Streamlingo</div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            isOpen: {
                section1: false,
                section2: false,
                section3: false,
                section4: false,
                section5: false,
                section6: false,
                section7: false,
                section8: false,
                section9: false,
                section10: false,
            }
        }
    },
    async mounted() {
    },
    computed: {
    },
    methods: {
        goToHome() {
            if (this.$store.state.user) this.$router.push('/dashboard')
            else this.$router.push('/')
        }
    },
}
</script>

<style></style>