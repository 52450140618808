<template>
    <div class="w-11/12 mx-auto grid grid-cols-1 divide-y divide-slate-300">
        <div class="text-xl text-bold ma-5 mt-10 flex justify-between">
            <span class="text-2xl">{{ $t('subscriptionOverview') }}</span>
        </div>
        <div>
            <PricingTable class="mt-8" @subscribe="handleSubscribe" :isLoading="isLoading" />
            <PriceCalculator class="mt-8 mb-16" />
        </div>
    </div>
</template>

<script>
import PricingTable from './PricingTable.vue';
import PriceCalculator from './PriceCalculator.vue';
import { functions, rtdb } from '@/firebaseInit';
import { get, child, ref } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';

export default {
    components: {
        PricingTable,
        PriceCalculator
    },
    data() {
        return {
            isLoading: false,
            cancelPlanImmediate: httpsCallable(functions, 'cancelPlanImmediate'),
        }
    },
    created() {
        this.getUserInformation();
        this.cancelPlanImmediate({ reduceColdStart: true });
    },
    methods: {
        getUserInformation() {
            get(child(ref(rtdb), `users/${this.$store.state.user.uid}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    this.subscriptionId = data.subscriptionId;
                } else {
                    console.log('Keine Daten verfügbar');
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        async handleSubscribe(plan, isAnnual) {
            this.isLoading = true
            if (!this.$store.state.planActive) {
                await this.$store.dispatch('checkoutPlan', { plan: plan, isAnnual, cancelUrl: this.$route.path });
            } else {
                await this.cancelPlanImmediate({ subscriptionId: this.subscriptionId });
                await this.$store.dispatch('checkoutPlan', { plan: plan, isAnnual, cancelUrl: this.$route.path });
            }
            this.isLoading = false
        },
    },
}
</script>

<style></style>