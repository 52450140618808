<template>
  <div class="button-component">
    <div class="overlap-group">
      <img class="play" :src="iconSrc" />
      <div class="text-wrapper" :style="{ color: textColor }">{{ language }}</div>
    </div>
  </div>
</template>

<script>
import playIcon from '@/assets/play.svg';
import stopIcon from '@/assets/stop.svg';
import deactivatedIcon from '@/assets/deactivated.svg';

export default {
  props: {
    language: {
      type: String,
      default: "English",
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isDeactivated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textColor() {
      if (this.isDeactivated) {
        return "#C2C2C2";
      } else if (this.isPlaying) {
        return "#35E126";
      } else {
        return "#339fff";
      }
    },
    iconSrc() {
      if (this.isDeactivated) {
        return deactivatedIcon;
      } else if (this.isPlaying) {
        return stopIcon;
      } else {
        return playIcon;
      }
    },
  },
};
</script>

<style>
/* Ihr bestehender CSS-Code */
.button-component {
  height: 79px;
  width: 279px;
}

.button-component .overlap-group {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #C2C2C2;
  border-radius: 20px;
  height: 81px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 281px;
}

.button-component .play {
  height: 40px;
  left: 22px;
  position: absolute;
  top: 20px;
  width: 40px;
}


.button-component .text-wrapper {
  color: #339fff;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 27px;
  font-weight: 400;
  height: 46px;
  left: 90px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26.5px;
  width: 122px;
}
</style>