import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importieren Sie den Router

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles'; // Import Vuetify styles
import '@mdi/font/css/materialdesignicons.css'; // Import MDI icons
import i18n from './languages/i18n';
import store from './store/index';
import './assets/tailwind.css';

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

// Globale Eigenschaften setzen
app.config.globalProperties.$SILENT_AUDIO_URL = "/silent.mp3"; // Für die Stummschaltung

const originalConsoleLog = console.log;
console.log = (...args) => {
  // Überprüfen Sie hier, ob die Meldung von Agora kommt und filtern Sie ggf. aus
  if (typeof args[0] === 'string' && args[0].includes('Agora')) return;
  originalConsoleLog.apply(console, args);
};

const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  // Überprüfen, ob die Warnung von intlify kommt und filtern
  if (typeof args[0] === 'string' && args[0].includes('[intlify]')) return;
  originalConsoleWarn.apply(console, args);
};


app.use(i18n);
app.use(vuetify);
app.use(router); // Vue Router verwenden
app.use(store); // Vuex Store verwenden

// Die App einhängen
app.mount('#app');
