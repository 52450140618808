<template>
    <div class="signup-modal">
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl font-bold">Sign Up</h2>
            <button @click="$emit('close')" class="text-gray-500 hover:text-gray-700">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                    </path>
                </svg>
            </button>
        </div>
        <form @submit.prevent="signup">
            <div class="mb-4">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input v-model="email" type="email" id="email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required>
            </div>
            <div class="mb-4">
                <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
                <input v-model="password" type="password" id="password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    required>
            </div>
            <div class="mb-6">
                <label for="confirm-password" class="block text-gray-700 text-sm font-bold mb-2">Confirm
                    Password</label>
                <input v-model="confirmPassword" type="password" id="confirm-password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    required>
            </div>
            <div class="mb-4">
                <label class="flex items-center">
                    <input v-model="acceptTerms" type="checkbox" class="form-checkbox h-5 w-5 text-indigo-600" required>
                    <span class="ml-2 text-gray-700">I accept the <a href="/terms-and-conditions" target="_blank"
                            class="text-indigo-600 hover:text-indigo-800 cursor-pointer">Terms and Conditions</a></span>
                </label>
            </div>
            <div class="flex items-center justify-between">
                <button type="submit"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Sign Up
                </button>
            </div>
        </form>
        <div class="mt-4 text-center">
            <p class="text-sm">
                Already have an account?
                <a @click="$emit('switch-to-login')"
                    class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Log in</a>
            </p>
        </div>
    </div>
</template>

<script>
import { authErrors } from '../firebaseErrorMapping';

export default {
    data() {
        return {
            email: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false
        }
    },
    methods: {
        async signup() {
            if (this.password !== this.confirmPassword) {
                this.$store.dispatch('showSnackbar', { message: 'Passwords do not match' });
                return;
            }
            if (!this.acceptTerms) {
                this.$store.dispatch('showSnackbar', { message: 'Please accept the Terms and Conditions' });
                return;
            }
            await this.$store.dispatch('signUp', {
                email: this.email,
                password: this.password,
            }).then(() => {
                this.$emit('success');
            }).catch((error) => {
                this.$store.dispatch('showSnackbar', { message: authErrors[error.code.replace('auth/', '')] || 'An unknown error occurred.' });
            });
        }
    }
}
</script>