<template>
    <div class="w-3/5 mx-auto grid grid-cols-1">
        <div class="text-xl text-bold pb-5 px-5 mt-10 flex justify-between border-b">
            <span class="text-2xl">{{ $t('termsAndConditions.termsAndConditions') }}</span>
        </div>
        <div class="mx-4">
            <div class="mt-6">
                <div @click="showTermsOfUse = !showTermsOfUse"
                    class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-b-0 borderdark-gray-200 rounded-tl-3xl focus:ring-4 focus:ring-gray-200 :focus:ring-gray-800 hover:bg-gray-100 gap-3"
                    :class="{ 'text-black bg-gray-100': showTermsOfUse }">
                    <span>{{ $t('termsAndConditions.termsOfUse') }}</span>
                    <svg class="w-3 h-3 shrink-0" :class="showTermsOfUse ? 'rotate-0' : 'rotate-180'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg>
                </div>
                <div v-if="showTermsOfUse">
                    <div class="p-5 border border-b-0 border-gray-200">
                        <ul class="list-decimal ml-6 mr-2">
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p1')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p2')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p3')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p4')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p5')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p6')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p7')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p8')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p9')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p10')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p11')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.termsOfUseText.p12')"></li>
                        </ul>
                    </div>
                </div>
                <div @click="showServiceDescription = !showServiceDescription"
                    class="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-b-0 borderdark-gray-200 focus:ring-4 focus:ring-gray-200 :focus:ring-gray-800 hover:bg-gray-100 gap-3"
                    :class="{ 'text-black bg-gray-100': showServiceDescription }">
                    <span>{{ $t('termsAndConditions.serviceDescription') }}</span>
                    <svg class="w-3 h-3 shrink-0" :class="showServiceDescription ? 'rotate-0' : 'rotate-180'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg>
                </div>
                <div v-if="showServiceDescription">
                    <div class="p-5 border border-b-0 border-gray-200">

                        <ul class="list-decimal ml-6 mr-2">
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.serviceDescriptionText.p1')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.serviceDescriptionText.p2')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.serviceDescriptionText.p3')"></li>
                        </ul>
                    </div>
                </div>
                <div @click="showDataProtection = !showDataProtection"
                    class="flex items-center justify-between w-full p-5 font-medium text-gray-500 rounded-br-3xl border borderdark-gray-200 focus:ring-4 focus:ring-gray-200 :focus:ring-gray-800 hover:bg-gray-100 gap-3"
                    :class="{ 'text-black bg-gray-100 border-b-0 rounded-br-none': showDataProtection }">
                    <span>{{ $t('termsAndConditions.privacyPolicy') }}</span>
                    <svg class="w-3 h-3 shrink-0" :class="showDataProtection ? 'rotate-0' : 'rotate-180'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg>
                </div>
                <div v-if="showDataProtection">
                    <div class="p-5 border border-gray-200 rounded-br-3xl">
                        <ul>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.privacyPolicyText.p1')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.privacyPolicyText.p2')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.privacyPolicyText.p3')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.privacyPolicyText.p4')"></li>
                            <li class="pl-4 mt-2" v-html="$t('termsAndConditions.privacyPolicyText.p5')"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8 mx-auto">
            <div class="flex cursor-pointer rounded-lg px-4 py-1 hover:text-sky-600" @click="$router.push('/')">
                <v-img :src="require('@/assets/logo.svg')" class="h-7 w-7 mr-3 my-auto cursor-pointer" contain></v-img>
                <div class="text-3xl font-medium">Streamlingo</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            showTermsOfUse: false,
            showServiceDescription: false,
            showDataProtection: false,
        }
    },
    async mounted() {
    },
    computed: {
    },
    methods: {
    },
}
</script>

<style></style>