<template>
    <section class="bg-gray-100 py-16">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 class="text-3xl font-bold text-center text-gray-900 mb-8">{{ $t('priceCalculator') }}</h2>
            <p class="text-center text-gray-600 mb-8">
                {{ $t('selectPlan') }}
            </p>

            <div class="bg-white rounded-lg shadow-xl p-6 md:p-8 lg:p-10">
                <div class="grid md:grid-cols-2 gap-8">
                    <!-- Left Column: Inputs -->
                    <div>
                        <!-- Pricing toggle -->
                        <div class="flex justify-center mb-8">
                            <div class="relative inline-flex bg-slate-200 rounded-xl py-1">
                                <div class="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
                                    <div class="absolute inset-0 w-1/2 bg-blue-600 rounded-full shadow-sm transform transition-transform duration-150 ease-in-out"
                                        :class="isAnnual ? 'translate-x-full' : 'translate-x-0'"></div>
                                </div>
                                <button @click="isAnnual = false" :class="isAnnual ? 'text-gray-500' : 'text-white'"
                                    class="relative w-24 px-3 py-1 text-sm font-medium rounded-full focus:outline-none">
                                    {{ $t('monthly') }}
                                </button>
                                <button @click="isAnnual = true" :class="isAnnual ? 'text-white' : 'text-gray-500'"
                                    class="relative w-24 px-3 py-1 text-sm font-medium rounded-full focus:outline-none">
                                    {{ $t('yearly') }}
                                </button>
                            </div>
                        </div>

                        <!-- Plan selection -->
                        <div class="mb-8">
                            <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t('selectPlan') }}</label>
                            <div class="flex space-x-2">
                                <button v-for="plan in ['Free', 'Base', 'Pro', 'Enterprise']" :key="plan"
                                    @click="selectedPlan = plan"
                                    :class="selectedPlan === plan ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'"
                                    class="flex-1 px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-colors duration-150">
                                    {{ plan }}
                                </button>
                            </div>
                        </div>

                        <!-- Sliders -->
                        <div class="space-y-6">
                            <div>
                                <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t('numberLanguages') }}
                                    {{ numberLanguages }}</label>
                                <input type="range" v-model="numberLanguages" :min="0" :max="maxLanguages" step="1"
                                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t('numberAIHours') }}
                                    {{ aiDuration }}</label>
                                <input type="range" v-model="aiDuration" :min="0" :max="maxAiDuration" step="1"
                                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700 mb-2">{{
                                    $t('numberInterpreterListeners') }} {{ numberInterpreters }}</label>
                                <input type="range" v-model="numberInterpreters" :min="0" :max="maxInterpreterListener"
                                    step="10" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700 mb-2">{{
                                    $t('numberInterpreterHours') }} {{ numberInterpreterDuration }}</label>
                                <input type="range" v-model="numberInterpreterDuration" :min="0"
                                    :max="maxInterpreterDuration" step="1"
                                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            </div>
                        </div>
                    </div>

                    <!-- Right Column: Results -->
                    <div class="space-y-6 mt-12">
                        <div class="bg-gray-100 p-4 rounded-lg">
                            <h3 class="text-lg font-semibold text-gray-900 mb-2">{{ $t('basePrice') }}</h3>
                            <p class="text-2xl font-bold text-blue-600">{{ plans[selectedPlan].price[pricePeriode] }} €
                            </p>
                        </div>
                        <div class="bg-green-100 p-4 rounded-lg">
                            <h3 class="text-lg font-semibold text-gray-900 mb-2">{{ $t('aiCosts') }}</h3>
                            <p class="text-2xl font-bold text-green-600">{{ aiCost }} €</p>
                            <p class="text-sm text-gray-600 mt-2">
                                {{ $t('inclusiveAre') }} {{ plans[selectedPlan].includedAIHours }} {{
                                $t('inclusiveAre2') }}
                            </p>
                            <p class="text-sm text-gray-600">
                                ( {{ $t('numberOfLanguages') }} * {{ $t('numberOfHours') }} * {{
                                    plans[selectedPlan].aiHoursPrice }} € )
                            </p>
                        </div>
                        <div class="bg-blue-100 p-4 rounded-lg">
                            <h3 class="text-lg font-semibold text-gray-900 mb-2">{{ $t('interpreterCosts') }}</h3>
                            <p class="text-2xl font-bold text-blue-600">{{ interpreterCost }} €</p>
                            <p class="text-sm text-gray-600 mt-2">
                                ( {{ $t('per10Listeners') }} * {{ $t('numberOfHours') }} * {{
                                    plans[selectedPlan].interpreterListenerPrice }} € )
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Total and Best Plan -->
                <div class="mt-8 pt-8 border-t border-gray-200">
                    <div class="flex flex-col md:flex-row justify-between items-center">
                        <div class="mb-4 md:mb-0">
                            <p class="text-gray-600">{{ $t('bestPlan') }}</p>
                            <button @click="selectedPlan = bestPlan"
                                class="mt-2 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 transition-colors duration-150">
                                {{ bestPlan }}
                            </button>
                        </div>
                        <div class="bg-blue-600 p-4 rounded-lg">
                            <h3 class="text-lg font-semibold text-white mb-2">{{ $t('totalPrice') }}</h3>
                            <p class="text-3xl font-bold text-white">
                                {{ plans[selectedPlan].price[pricePeriode] + aiCost + interpreterCost }} €
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// Das Script bleibt größtenteils unverändert
export default {
    data() {
        return {
            isAnnual: true,
            selectedPlan: 'Base',
            numberLanguages: 1,
            maxLanguages: 5,
            aiDuration: 5,
            maxAiDuration: 8,
            numberInterpreters: 0,
            maxInterpreterListener: 50,
            numberInterpreterDuration: 0,
            maxInterpreterDuration: 8,
            plans: {
                'Free': {
                    name: 'Free',
                    price: { yearly: 0, monthly: 0 },
                    includedAIHours: 0,
                    aiHoursPrice: 10,
                    interpreterListenerPrice: 2.5,
                },
                'Base': {
                    name: 'Base',
                    price: { yearly: 40, monthly: 48 },
                    includedAIHours: 5,
                    aiHoursPrice: 6,
                    interpreterListenerPrice: 2,
                },
                'Pro': {
                    name: 'Pro',
                    price: { yearly: 80, monthly: 96 },
                    includedAIHours: 15,
                    aiHoursPrice: 5,
                    interpreterListenerPrice: 1.5,
                },
                'Enterprise': {
                    name: 'Enterprise',
                    price: { yearly: 180, monthly: 196 },
                    includedAIHours: 40,
                    aiHoursPrice: 4,
                    interpreterListenerPrice: 1,
                },
            },
        };
    },
    watch: {
        numberLanguages() {
            if (this.numberLanguages > 4) {
                this.maxLanguages = 20;
            } else if (this.numberLanguages == 0) {
                this.maxLanguages = 5;
            }
        },
        aiDuration() {
            if (this.aiDuration > 7) {
                this.maxAiDuration = 25;
            } else if (this.aiDuration == 0) {
                this.maxAiDuration = 8;
            }
        },
        numberInterpreters() {
            if (this.numberInterpreters > 49) {
                this.maxInterpreterListener = 500;
            } else if (this.numberInterpreters == 0) {
                this.maxInterpreterListener = 50;
            }
        },
        numberInterpreterDuration() {
            if (this.numberInterpreterDuration > 7) {
                this.maxInterpreterDuration = 25;
            } else if (this.numberInterpreterDuration == 0) {
                this.maxInterpreterDuration = 8;
            }
        },
    },
    computed: {
        pricePeriode() {
            return this.isAnnual ? 'yearly' : 'monthly';
        },
        aiCost() {
            return this.calculateAiCost(this.selectedPlan);
        },
        interpreterCost() {
            return this.calculateInterpreterCost(this.selectedPlan);
        },
        bestPlan() {
            let bestPlan = 'Free';
            let bestPrice = this.plans['Free'].price[this.pricePeriode] + this.calculateAiCost('Free') + this.calculateInterpreterCost('Free');
            for (const plan in this.plans) {
                const price = this.plans[plan].price[this.pricePeriode] + this.calculateAiCost(plan) + this.calculateInterpreterCost(plan);
                if (price <= bestPrice) {
                    bestPrice = price;
                    bestPlan = plan;
                }
            }
            return bestPlan;
        },
    },
    methods: {
        calculateAiCost(plan) {
            const aiLanguageDuration = this.numberLanguages * this.aiDuration;
            if (aiLanguageDuration <= this.plans[plan].includedAIHours) {
                return 0;
            } else {
                return (aiLanguageDuration - this.plans[plan].includedAIHours) * this.plans[plan].aiHoursPrice;
            }
        },
        calculateInterpreterCost(plan) {
            return this.numberInterpreters / 10 * this.numberInterpreterDuration * this.plans[plan].interpreterListenerPrice;
        },
    },
};
</script>