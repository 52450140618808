<template>
    <div class="w-4/5 mx-auto grid grid-cols-1 divide-y divide-slate-300">
        <div class="text-xl text-bold ma-5 mt-10 flex justify-between">
            <span class="text-2xl">Impressum</span>
            <span class="rounded-xl border px-5 hover:bg-slate-100 hover:text-blue-400 hover:cursor-pointer" @click="$router.push('/')">Home</span>
        </div>
        <div>
            <div class="ml-8 mt-4">
                <div class="font-bold text-lg">
                    Angaben gemäß § 5 TMG
                </div>
                <div class="mt-2 ml-4">
                    Tim-Cedric Inhoff <br>
                    Ludwigsburger Str. 20 <br>
                    76228 Karlsruhe <br>
                    Deutschland
                </div>
                <div class="font-bold text-lg mt-6">
                    Vertreten durch:
                </div>
                <div class="mt-2 ml-4">
                    Tim-Cedric Inhoff
                </div>
                <div class="font-bold text-lg mt-6">
                    Kontakt:
                </div>
                <div class="mt-2 ml-4">
                    E-Mail: info@streamlingo.net
                </div>
                <div class="font-bold text-lg mt-6">
                    Umsatzsteuer-ID:
                </div>
                <div class="mt-2 ml-4">
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE344219776
                </div>
                <div class="font-bold text-lg mt-6">
                    Berufsbezeichnung:
                </div>
                <div class="mt-2 ml-4">
                    IT und Mediendienstleistungen
                </div>
                <div class="font-bold text-lg mt-6">
                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                </div>
                <div class="mt-2 ml-4">
                    Tim-Cedric Inhoff <br>
                    Ludwigsburger Str. 20 <br>
                    76228 Karlsruhe <br>
                    Deutschland
                </div>
                <div class="font-bold text-lg mt-6">
                    Hinweis:
                </div>
                <div class="mt-2 ml-4">
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer
                    Links. <br>
                    Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
        }
    },
    async mounted() {
    },
    computed: {
    },
    methods: {
    },
}
</script>

<style></style>