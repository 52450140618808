<template>
    <div class="coming-soon">
      <h1>Coming Soon</h1>
      <p>Diese Seite befindet sich im Aufbau. Bitte haben Sie noch etwas Geduld.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ComingSoon'
  }
  </script>
  
  <style scoped>
  .coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  </style>