<template>
    <div v-if="!this.$store.state.planActive">
        <div class="text-center text-2xl font-bold mt-16">{{ $t('mobileDashboard.viewDenied') }}
        </div>
        <div class="text-center mt-6 w-4/5 mx-auto">{{ $t('mobileDashboard.viewDeniedText') }}
        </div>
        <div class="rounded-xl border mx-auto px-3 w-fit bg-slate-200 hover:bg-slate-300 mt-8"
            @click="$router.push('/')">{{ $t('mobileDashboard.planOverview') }}
        </div>
    </div>
    <div class="w-4/5 mx-auto grid grid-cols-1 divide-y divide-slate-300" v-else>
        <div class="mt-10 mb-4 flex justify-center">
            <span class="text-xl">{{ $t('mobileDashboard.mobileDashboard') }}</span>
        </div>
        <div>
            <div class="ml-1 mt-3 text-sm">{{ $t('mobileDashboard.conferenceName') }} {{ conferenceName }}</div>
            <div class="text-sky-600  text-sm cursor-pointer ml-1 mt-1 hover:text-sky-800"
                @click="$store.dispatch('showQRModal', { link: listenUrl, text: $t('mobileDashboard.conferenceURL') })">
                {{ $t('mobileDashboard.showQRCode') }}
            </div>
            <div class="mt-5 min-h-[60%] overflow-y-auto">
                <div class="text-sm">{{ $t('mobileDashboard.languageSelection') }}

                </div>
                <mobile-language-button :propsChannel="originalChannel"
                    :propsCurrentListeners="listeners[0]?.currentListeners ? listeners[0]?.currentListeners : 0"
                    class="mt-3" />
                <mobile-language-button v-for="languageButton in languageButtons" :key="languageButton.id"
                    :propsChannel="languageButton"
                    :propsCurrentListeners="listeners[languageButton.id]?.currentListeners ? listeners[languageButton.id]?.currentListeners : 0"
                    class="mt-3" />
            </div>
            <!-- <div class="text-center mt-16 text-red">
                Zur Zeit gibt es leider ein paar technische Probleme, wodurch die Übersetzungsfernsteuerung leider nicht
                funktioniert. <br>
                Wir entschuldigen uns für die Unannehmlichkeiten und arbeiten daran, das Problem so schnell wie möglich zu beheben.
            </div> -->
            <div class="mt-4 mb-12 h-[120px] grid grid-cols-1 justify-center item-center">
                <div v-if="recordingStatus === 'ended'" class="mt-10">
                    <div class="border rounded-xl h-[50px] flex justify-center items-center bg-blue-400 hover:bg-blue-500 mt-4"
                        @click="setLiveTranslation(true)">
                        <div class="text-white">{{ $t('mobileDashboard.startTranslation') }}

                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="border rounded-xl h-[50px] flex justify-center items-center bg-red-500 hover:bg-red-600 mt-2"
                        v-if="recordingStatus === 'running'" @click="setAITranslation(false)">
                        <div class="text-white">{{ $t('mobileDashboard.pauseAITranslation') }}

                        </div>
                    </div>
                    <div class="border rounded-xl h-[50px] flex justify-center items-center bg-blue-400 hover:bg-blue-500 mt-2"
                        v-else @click="setAITranslation(true)">
                        <div class="text-white">{{ $t('mobileDashboard.continueAITranslation') }}
                        </div>
                    </div>
                    <div class="border rounded-xl h-[50px] flex justify-center items-center bg-red-500 hover:bg-red-600 mt-2"
                        @click="setLiveTranslation(false)">
                        <div class="text-white">{{ $t('mobileDashboard.stopTranslation') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileLanguageButton from './MobileLanguageButton.vue';
import { ref, off, onValue, update } from 'firebase/database';
import { rtdb } from '../firebaseInit';


export default {
    components: {
        MobileLanguageButton,
    },
    data() {
        return {
            FrontendUrl: window.location.origin,
            conferenceName: '',
            conferenceUrl: '',
            conferenceId: '799977',
            streamCode: '',
            selectedSourceLanguage: '',
            channels: [],
            originalChannel: {},
            languageButtons: [],
            listeners: 0,
            remoteRecordingStatus: {
                liveTranslationActive: false,
                aiTranslationActive: true,
                initiatedBy: 'mobile'
            },
            recordingStatus: 'ended',
        }
    },
    async created() {
        this.getConferenceInformation().then(() => {
            this.watchListenerChanges();
            this.watchRecordingStatus();
        });
    },
    computed: {
        listenUrl() {
            return `${this.FrontendUrl}/listen/${this.conferenceUrl}`;
        },
    },
    methods: {
        async setLiveTranslation(value) {
            this.remoteRecordingStatus.liveTranslationActive = value;
            await this.updateRemoteRecordingStatus();
        },
        async setAITranslation(value) {
            this.remoteRecordingStatus.aiTranslationActive = value;
            await this.updateRemoteRecordingStatus();
        },
        watchListenerChanges() {
            const listenerRef = ref(rtdb, `conferences/${this.conferenceId}/listeners`);
            onValue(listenerRef, (snapshot) => {
                if (snapshot.exists()) {
                    this.listeners = snapshot.val();
                }
            });
        },
        async waitForRef(ref, timeout = 5000) {
            let timeoutId;
            const cleanup = () => {
                clearTimeout(timeoutId);
                off(ref);
            };
            return new Promise((resolve, reject) => {
                timeoutId = setTimeout(() => { cleanup(); reject(new Error('Timeout waiting for user data')); }, timeout); // Timeout after 5 seconds
                onValue(ref, (snapshot) => {
                    if (snapshot.exists()) {
                        cleanup(); resolve(snapshot.val());
                    }
                }, (error) => { cleanup(); reject(error); });
            });
        },
        async getConferenceInformation() {
            const statusRef = ref(rtdb, `users/${this.$store.state.user.uid}`);
            const statusSnapshot = await this.waitForRef(statusRef).catch(() => window.location.reload());
            const conferenceRef = ref(rtdb, `conferences/${statusSnapshot.conferenceId}`);
            const conferenceSnapshot = await this.waitForRef(conferenceRef).catch(() => window.location.reload());

            this.conferenceName = conferenceSnapshot.conferenceName;
            this.conferenceUrl = conferenceSnapshot.conferenceUrl;
            this.conferenceId = conferenceSnapshot.conferenceId;
            this.streamCode = conferenceSnapshot.conferenceId;
            this.selectedSourceLanguage = conferenceSnapshot.sourceLanguage;
            if (conferenceSnapshot.channels && conferenceSnapshot.channels.length > 0) {
                this.channels = conferenceSnapshot.channels.slice(1);
                this.originalChannel = conferenceSnapshot.channels[0];
            } else {
                this.channels = [];
                this.originalName = "Originalton";
            }
            this.loadSettings();
        },
        loadSettings() {
            if (this.channels) {
                this.languageButtons = this.channels.map(channel => {
                    return {
                        id: channel.id,
                        channelName: channel.channelName,
                        isActive: channel.isActive,
                        useAI: channel.useAI,
                        speakerStreamCode: channel.speakerStreamCode,
                        selectedTargetLanguage: channel.targetLanguage,
                    };
                });
            }
        },
        watchRecordingStatus() {
            const recordingStatusRef = ref(rtdb, `conferences/${this.conferenceId}/recordingStatus`);
            onValue(recordingStatusRef, (snapshot) => {
                if (snapshot.exists() && JSON.stringify(this.recordingStatus) !== JSON.stringify(snapshot.val())) {
                    this.recordingStatus = snapshot.val();
                }
            });
        },
        async updateRemoteRecordingStatus() {
            const conferenceRemoteRef = ref(rtdb, `conferences/${this.conferenceId}/remoteRecordingStatus`);
            const remoteRecordingStatus = this.remoteRecordingStatus;
            remoteRecordingStatus.initiatedBy = 'mobile';
            return await update(conferenceRemoteRef, remoteRecordingStatus);
        },
    },
}
</script>

<style></style>