<template>
    <div class="w-4/5 mx-auto grid grid-cols-1 divide-y divide-slate-300">
        <div class="text-xl text-bold ma-5 mt-10 flex justify-between">
            <span class="text-2xl">{{ $t('usage.usage') }}</span>
            <div class="flex items-center border border-2-slate-200 rounded-lg">
                <button @click="decrementMonth" class="hover:bg-slate-200 text-gray-800 ma-1 px-2 rounded-lg"
                    v-text="'<'">
                </button>
                <span class="mx-2 border-black">{{ currentMonth.toLocaleString(localLanguage, {
                    month: 'long',
                    year: 'numeric'
                }) }}</span>
                <button @click="incrementMonth" class="text-gray-800 ma-1 px-2 rounded-lg"
                    :class="nextMonthActive ? 'hover:bg-slate-200' : 'cursor-default'" v-text="'>'">
                </button>
            </div>
        </div>
        <div class="px-4 ">
            <div class="mt-7 mb-5 text-lg w-full h-[270px] shadow-md rounded-lg px-4 py-10">
                {{ $t('usage.montlyCosts') }} {{ Math.round(monthlyCost * 100) / 100 }} €
                <canvas ref="costChart"></canvas>
            </div>
            <div class="mb-5 text-lg w-full h-[270px] shadow-md rounded-lg px-4 py-10">
                {{ $t('usage.aiTranslation') }}
                <canvas ref="translationChart"></canvas>
            </div>
            <div class="mb-15 text-lg w-full h-[270px] shadow-md rounded-lg px-4 py-10">
                {{ $t('usage.listenerCount') }}
                <canvas ref="listenerChart"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../firebaseInit';
import { collection, getDocs } from 'firebase/firestore';
import Chart from 'chart.js/auto';
import { languages_detailed } from '../languages_detailed';


export default {
    data() {
        return {
            usageData: [],
            monthlyCost: 0,
            currentMonth: new Date(),
        }
    },
    async mounted() {
        await this.getUsage();
        this.renderCharts();
    },
    computed: {
        nextMonthActive() {
            const newDate = new Date(this.currentMonth);
            return new Date(newDate.setMonth(newDate.getMonth() + 1)) < new Date();
        },
        localLanguage() {
            return this.$i18n.locale === 'de'? 'de-DE' : 'en-US'
        }
    },
    methods: {
        getMonthData(month, year) {
            const start = new Date(year, month, 1);
            const end = new Date(year, month + 1, 0);

            const monthData = [];
            for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
                const day = new Date(d);
                const dayData = this.usageData.filter(data => data.date.toDateString() === day.toDateString());
                const maxListenersPerChannel = dayData.reduce((acc, data) => {
                    data.maxListenersPerChannel.forEach(channel => {
                        const existingChannel = acc.find(c => c.channelId === channel.channelId);
                        if (existingChannel) {
                            existingChannel.listeners += channel.listeners;
                        } else {
                            acc.push({ ...channel });
                        }
                    });
                    return acc;
                }, []);
                const translationDuration = dayData.reduce((acc, data) => {
                    Object.keys(data.translationDuration).forEach(lang => {
                        acc[lang] = (acc[lang] || 0) + data.translationDuration[lang];
                    });
                    return acc;
                }, {});
                const totalDuration = dayData.reduce((sum, data) => sum + data.totalDuration, 0);
                const maxListeners = dayData.reduce((max, data) => Math.max(max, data.maxListeners), 0);
                const kiCosts = dayData.reduce((sum, data) => sum + data.kiCosts, 0);
                const listenerCosts = dayData.reduce((sum, data) => sum + data.listenerCosts, 0);
                monthData.push({
                    date: day,
                    kiCosts,
                    listenerCosts,
                    maxListenersPerChannel,
                    translationDuration,
                    totalDuration,
                    maxListeners,
                });
            }
            this.monthlyCost = monthData.reduce((sum, data) => sum + data.kiCosts + data.listenerCosts, 0);

            return monthData;
        },
        renderCharts() {
            this.destroyCharts();
            const monthData = this.getMonthData(this.currentMonth.getMonth(), this.currentMonth.getFullYear());
            this.costChart = this.renderCostChart(monthData);
            this.translationChart = this.renderTranslationChart(monthData);
            this.listenerChart = this.renderListenerChart(monthData);
        },
        destroyCharts() {
            if (this.costChart) this.costChart.destroy();
            if (this.translationChart) this.translationChart.destroy();
            if (this.listenerChart) this.listenerChart.destroy();
        },
        incrementMonth() {
            if (this.nextMonthActive) {
                this.currentMonth = new Date(this.currentMonth.setMonth(this.currentMonth.getMonth() + 1));
                this.renderCharts();
            }
        },
        decrementMonth() {
            this.currentMonth = new Date(this.currentMonth.setMonth(this.currentMonth.getMonth() - 1));
            this.renderCharts();
        },
        getLanguageName(code) {
            return languages_detailed.find(lang => lang.code === code).name;
        },
        async getUsage() {
            const uid = this.$store.state.user.uid;
            const querySnapshot = await getDocs(collection(db, "logging", uid, "completedRecordings"));
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.date = data.date.toDate();
                this.usageData.push(data);
            });

            const costTable = {
                aiTranslationCosts: {
                    'Free': 10,
                    'Base': 6,
                    'Pro': 5,
                    'Enterprise': 4,
                },
                interpreterCosts: {
                    'Free': 2.5,
                    'Base': 2,
                    'Pro': 1.5,
                    'Enterprise': 1,
                }
            }
            const monthlyFreeMinutes = {
                'Free': 0,
                'Base': 300,
                'Pro': 900,
                'Enterprise': 2400,
            }
            const tier = this.$store.state.tier;

            this.usageData.forEach(data => {
                let kiCosts = data.billedAIDurationSeconds / 3600 * costTable.aiTranslationCosts[tier];
                if (tier !== 'Free') {
                    kiCosts = Math.max(0, kiCosts - monthlyFreeMinutes[tier]);
                }
                const listenerCosts = data.billedInterpreterListenerDurationSeconds / 3600 * costTable.interpreterCosts[tier];
                // Füge diese Kosten dem data-Objekt hinzu, um sie später im Diagramm zu verwenden

                data.kiCosts = kiCosts ? Math.round(kiCosts * 100) / 100 : 0;
                data.listenerCosts = listenerCosts ? Math.round(listenerCosts * 100) / 100 : 0;
            });
        },
        getChartLabels(monthData) {
            return monthData.map(data => `${data.date.getDate()}. ${data.date.toLocaleString(this.localLanguage, { month: 'long' })}`);
        },
        renderCostChart(monthData) {
            const ctx = this.$refs.costChart.getContext('2d');
            return new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.getChartLabels(monthData),
                    datasets: [{
                        label: this.$t('usage.labels.aiCosts'),
                        data: monthData.map(data => data.kiCosts),
                    }, {
                        label: this.$t('usage.labels.aiCosts'),
                        data: monthData.map(data => data.listenerCosts),
                    }]
                },
                options: this.getChartOptions(monthData, this.$t('usage.labels.costs'))
            });
        },
        renderTranslationChart(monthData) {
            const ctx = this.$refs.translationChart.getContext('2d');
            const datasets = [];
            const languages = new Set();

            // Sammle alle verwendeten Sprachen
            for (const day of monthData) {
                for (const lang of Object.keys(day.translationDuration)) {
                    languages.add(lang);
                }
            }

            // Erstelle Datensätze für jede Sprache und fülle fehlende Werte mit 0
            for (const lang of languages) {
                const dataset = {
                    label: this.getLanguageName(lang),
                    data: [],
                };

                for (const day of monthData) {
                    const duration = day.translationDuration[lang] || 0;
                    dataset.data.push(Math.round(duration / 60 * 10) / 10);
                }

                datasets.push(dataset);
            }

            return new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.getChartLabels(monthData),
                    datasets: datasets,
                },
                options: this.getChartOptions(monthData, this.$t('usage.labels.duration'))
            });
        },
        renderListenerChart(monthData) {
            const ctx = this.$refs.listenerChart.getContext('2d');
            const datasets = [];
            const channels = new Set();

            // Sammle alle vorhandenen Kanäle
            for (const day of monthData) {
                for (const channel of day.maxListenersPerChannel) {
                    channels.add({ channelId: channel.channelId, channelName: channel.channelName });
                }
            }

            for (const channel of channels) {
                const dataset = {
                    label: channel.channelName,
                    data: [],
                };

                for (const day of monthData) {
                    const listeners = day.maxListenersPerChannel.find(c => c.channelId === channel.channelId)?.listeners || 0;
                    dataset.data.push(listeners);
                }

                datasets.push(dataset);
            }

            return new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.getChartLabels(monthData),
                    datasets: datasets,
                },
                options: this.getChartOptions(monthData, this.$t('usage.labels.listenerCount'))
            });
        },
        getChartOptions(monthData, yTitle) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            callback: (value, index) => {
                                if (index % 7 !== 0) return '';
                                const date = monthData[index].date;
                                return `${date.getDate()}. ${date.toLocaleString(this.localLanguage, { month: 'long' })}`;
                            }
                        },
                        grid: { display: false }
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: yTitle
                        }
                    }
                }
            }
        },

    }
}
</script>

<style></style>