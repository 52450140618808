<template>
    <div class="border rounded-xl h-[50px] flex items-center">
        <div class="w-[18px] h-[18px] ml-4 -mt-[6px]">
            <input type="checkbox" :checked="channel.isActive" class=" border-slate-400 rounded" disabled />
        </div>
        <div class="ml-2 w-[80px] text-sm">
            {{ channel.channelName }}
        </div>
        <div class="ml-3 w-[18px] h-[18px]">
            <img :src="accountVoiceIcon" v-if="!channel.useAI" />
            <img :src="aiIcon" v-else />
        </div>
        <div class="ml-8 w-[30px]" v-if="!channel.useAI">
            <img :src="qrCodeIcon" class="w-[18px] h-[18px]"
                @click="$store.dispatch('showQRModal', { link: qrCodeLink, text: channel.id === 0 ? $t('mobileDashboard.conferenceURL') : $t('mobileDashboard.interpreterURL')  })" />
        </div>
        <div class="ml-8 w-[30px] text-sm" v-else>
            {{ channel.selectedTargetLanguage.code }}
        </div>
        <div class="ml-4 flex">
            <img :src="accountGroupIcon" class="w-[18px] h-[18px] m-1"/>:
            {{ currentListeners }}
        </div>
    </div>
</template>

<script>
import accountVoiceIcon from '@/assets/account-voice.svg';
import accountGroupIcon from '@/assets/account-group.svg';
import aiIcon from '@/assets/ai-icon.svg';
import qrCodeIcon from '@/assets/qrcode.svg';

export default {
    props: {
        propsChannel: {
            type: Object,
            required: true,
        },
        propsCurrentListeners: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            accountVoiceIcon,
            aiIcon,
            qrCodeIcon,
            accountGroupIcon,
            channel: this.propsChannel,
            currentListeners: this.propsCurrentListeners,
        }
    },
    watch: {
        propsChannel() {
            this.channel = this.propsChannel;
        },
        propsCurrentListeners() {
            this.currentListeners = this.propsCurrentListeners;
        },
    },
    computed: {
        qrCodeLink() {
            if (this.channel.id === 0)
                return `${window.location.origin}/listen/${this.channel.speakerStreamCode}`;
            return `${window.location.origin}/speaker/${this.channel.speakerStreamCode}`;
        },
    },
    methods: {
    },
}
</script>
