<template>
    <div>
        <!-- Nav Bar -->
        <header>
            <nav class="bg-gray-800 border-gray-200 px-4 lg:px-6 py-2.5">
                <div class="flex flex-wrap justify-between items-center mx-auto ">
                    <a href="#" class="flex items-center">
                        <img :src="require('@/assets/logo.svg')" class="mr-3 h-6 sm:h-9" alt="Streamlingo Logo" />
                        <span class="self-center text-xl font-semibold whitespace-nowrap text-white">Streamlingo</span>
                    </a>
                    <div class="flex items-center lg:order-2">
                        <div class="relative inline-block">
                            <div class="px-3 py-1 text-center rounded-md hover:bg-gray-700 inline-flex items-center cursor-pointer justify-between"
                                @click="isLanguageMenuOpen = !isLanguageMenuOpen" ref="languageMenu">
                                <img :src="getCurrentLanguageFlag()" alt="Current language flag"
                                    class="w-5 h-5 mr-2 rounded-xl">
                                <span class="font-medium text-gray-300">{{ $t('language') }}</span>
                                <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 10 6" v-if="!isLanguageMenuOpen">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" d="m1 1 4 4 4-4" class="text-gray-300" />
                                </svg>
                                <svg class="w-2.5 h-2.5 ms-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 10 6" v-else>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" d="m1 1 4 4 4-4" class="text-gray-300" />
                                </svg>
                            </div>
                            <!-- Dropdown menu -->
                            <div v-if="isLanguageMenuOpen"
                                class="absolute left-0 mt-2 bg-gray-700 rounded-lg shadow z-10">
                                <ul class="p-3 space-y-1 text-sm text-gray-300">
                                    <li v-for="(language, index) in supportedLanguages" :key="index">
                                        <div class="flex items-center p-2 rounded hover:bg-gray-600 px-3"
                                            @click="changeLanguage(language)">
                                            <img :src="getCurrentLanguageFlag(language.locale)"
                                                alt="Current language flag" class="w-4 h-4 mr-2 rounded-xl">
                                            <span>{{ language.name }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button @click="isMobileMenuOpen = !isMobileMenuOpen" type="button"
                            class="inline-flex items-center p-1 md:p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg" v-if="!isMobileMenuOpen">
                                <path fill-rule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg" v-else>
                                <path fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <div :class="{ 'hidden': !isMobileMenuOpen }"
                        class="justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
                        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <a href="#hero" @click="scrollToSection('hero')"
                                    class="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
                                    aria-current="page">{{ $t('home.navigation.home') }}</a>
                            </li>
                            <li>
                                <a href="#benefits" @click="scrollToSection('benefits')" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.benefits') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#how-it-works" @click="scrollToSection('how-it-works')" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.howItWorks') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#pricing" @click="scrollToSection('pricing')" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.pricing') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#faq" @click="scrollToSection('faq')" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.faq') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li v-if="$store.state.user">
                                <a href="/dashboard" class="block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.dashboard') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li v-if="!$store.state.user">
                                <a @click="showLoginModal = true" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.login') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                            <li v-if="!$store.state.user">
                                <a @click="showSignupModal = true" class="block py-2 pr-4 pl-3 text-gray-600 border-b border-gray-100 
              hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 
              lg:hover:text-white lg:p-0 dark:text-gray-400 lg:dark:hover:text-white 
              dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent 
              dark:border-gray-700 relative group">
                                    <span>{{ $t('home.navigation.signup') }}</span>
                                    <span
                                        class="absolute left-0 bottom-0 w-full h-0.5 bg-gray-400 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        <!-- Hero Section -->
        <section id="hero" class="bg-gray-900 text-white">
            <div class="px-4 py-32 mx-auto h-screen lg:items-center lg:flex">
                <div class="mx-auto text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        {{ $t('home.heroTitle') }}
                        <strong class="font-extrabold text-[#86bfc3] sm:block">
                            {{ $t('home.heroSubtitle') }}
                        </strong>
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        {{ $t('home.heroTagline') }}
                    </p>

                    <div class="flex flex-wrap justify-center gap-4 mt-8">
                        <a class="block px-12 py-3 text-sm font-medium text-white bg-blue-600 rounded shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                            @click="showSignupModal = true">
                            {{ $t('home.tryForFree') }}
                        </a>

                        <a class="block px-12 py-3 text-sm font-medium text-blue-600 rounded shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500 sm:w-auto"
                            href="#video" @click="scrollToSection('video')">
                            {{ $t('home.learnMore') }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- Video Section -->
        <section id="video" class="bg-white py-16">
            <div class=" px-4 py-4 mx-auto">
                <h2 class="text-3xl font-bold text-center mb-8">{{ $t('home.videoTitle') }}</h2>
                <div class="aspect-video w-11/12 md:w-4/5 mx-auto">
                    <iframe src="https://www.youtube.com/embed/gJSdOW5A_Z8?si=t_F9zpaf7W0YOUAY"
                        title="Streamlingo Erklärvideo" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen class="w-full h-full"></iframe>
                </div>
            </div>
        </section>
        <!-- Vorteile Section -->
        <section id="benefits" class="bg-gray-100 py-16">
            <div class="px-4 mx-auto">
                <h2 class="text-3xl font-bold text-center mb-12">{{ $t('home.benefitsTitle') }}</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div v-for="(benefit, index) in benefits" :key="index" class="bg-white p-6 rounded-lg shadow-md">
                        <div class="text-blue-600 mb-4">
                            <component :is="benefit.icon" class="w-12 h-12" />
                        </div>
                        <h3 class="text-xl font-semibold mb-2">{{ benefit.title }}</h3>
                        <p class="text-gray-600">{{ benefit.description }}</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Wie es funktioniert -->
        <section id="how-it-works" class="bg-white py-16">
            <div class=" px-4 mx-auto">
                <h2 class="text-3xl font-bold text-center mb-12">{{ $t('home.howItWorksTitle') }}</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div v-for="(step, index) in steps" :key="index" class="text-center">
                        <div
                            class="bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                            {{ index + 1 }}
                        </div>
                        <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>
                        <p class="text-gray-600">{{ step.description }}</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Preis Section -->
        <section id="pricing" class="bg-gray-100 py-16">
            <div class=" px-4 mx-auto">
                <h2 class="text-3xl font-bold text-center mb-12">{{ $t('home.pricingTitle') }}</h2>
            </div>
            <PricingTable class="mx-auto mt-6 w-11/12" @subscribe="handleSubscribe"></PricingTable>
        </section>
        <!-- Preis Berechner Section -->
        <section class="bg-gray-100 py-16">
            <PriceCalculator class="mx-auto mt-6"></PriceCalculator>
        </section>
        <!-- Testamonals bisher noch Demo daher ausgeblendet -->
        <!-- <section class="bg-gray-100 py-16">
        <div class=" px-4 mx-auto">
            <h2 class="text-3xl font-bold text-center mb-12">Was unsere Kunden sagen</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div v-for="(testimonial, index) in testimonials" :key="index"
                    class="bg-white p-6 rounded-lg shadow-md">
                    <p class="text-gray-600 mb-4">"{{ testimonial.quote }}"</p>
                    <div class="flex items-center">
                        <img :src="testimonial.avatar" :alt="testimonial.name" class="w-12 h-12 rounded-full mr-4">
                        <div>
                            <h4 class="font-semibold">{{ testimonial.name }}</h4>
                            <p class="text-gray-500">{{ testimonial.position }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
        <!-- FAQ Section -->
        <section id="faq" class="bg-gray-100 py-16">
            <div class="max-w-screen-xl px-4 mx-auto">
                <h2 class="text-3xl font-bold text-center mb-8">{{ $t('home.faqTitle') }}</h2>
                <div class="space-y-4">
                    <div v-for="(faq, index) in faqs" :key="index" class="bg-white rounded-lg shadow-md">
                        <button @click="toggleFAQ(index)"
                            class="flex justify-between items-center w-full px-4 py-4 text-left">
                            <span class="font-semibold">{{ faq.question }}</span>
                            <svg class="w-6 h-6 transform transition-transform duration-200"
                                :class="{ 'rotate-180': faq.isOpen }" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                            </svg>
                        </button>
                        <div v-show="faq.isOpen" class="px-4 pb-4">
                            <p>{{ faq.answer }}</p>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-8">
                    <a href="/faq" class="text-blue-600 hover:text-blue-800 font-semibold">{{ $t('home.faq.viewAllFAQs')
                        }}</a>
                </div>
            </div>
        </section>
        <!-- Call to Action Section -->
        <section class="bg-blue-600 text-white py-16">
            <div class=" px-4 mx-auto text-center">
                <h2 class="text-3xl font-bold mb-4">{{ $t('home.ctaTitle') }}</h2>
                <p class="mb-8">{{ $t('home.ctaSubtitle') }}</p>
                <a @click="showSignupModal = true"
                    class="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300 cursor-pointer">
                    {{ $t('home.ctaButton') }}
                </a>
            </div>
        </section>
        <!-- Footer -->
        <footer class="bg-gray-800 text-white">
            <div class="max-w-screen-xl px-4 pt-16 pb-6 mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    <div>
                        <img src="@/assets/logo.svg" class="mr-5 h-6 sm:h-9" alt="Streamlingo Logo" />
                        <p class="max-w-xs mt-4 text-sm text-gray-400">
                            {{ $t('home.footerTagline') }}
                        </p>
                        <!-- <div class="flex mt-8 space-x-6 text-gray-400">
                            <a class="hover:opacity-75" href="#" target="_blank" rel="noreferrer">
                                <span class="sr-only"> Facebook </span>
                                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                        clip-rule="evenodd" />
                                </svg>
                            </a>
                            <a class="hover:opacity-75" href="#" target="_blank" rel="noreferrer">
                                <span class="sr-only"> Instagram </span>
                                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                        clip-rule="evenodd" />
                                </svg>
                            </a>
                            <a class="hover:opacity-75" href="#" target="_blank" rel="noreferrer">
                                <span class="sr-only"> Twitter </span>
                                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                            </a>
                        </div> -->
                    </div>

                    <div class="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
                        <div>
                            <p class="font-medium">{{ $t('home.footer.company') }}</p>
                            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
                                <a class="hover:opacity-75" href="/company#about" target="_blank"> {{
                                    $t('home.footer.aboutUs') }} </a>
                                <a class="hover:opacity-75" href="/company#team" target="_blank"> {{
                                    $t('home.footer.team') }} </a>
                                <!-- <a class="hover:opacity-75" href="#"> Karriere </a> -->
                                <a class="hover:opacity-75" href="/company#impressum" target="_blank"> {{
                                    $t('home.footer.impressum') }} </a>
                            </nav>
                        </div>
                        <div>
                            <p class="font-medium">{{ $t('home.footer.services') }}</p>
                            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
                                <a class="hover:opacity-75" href="/services#ai-translation" target="_blank">
                                    {{ $t('home.footer.aiTranslation') }} </a>
                                <a class="hover:opacity-75" href="/services#audio-transmission" target="_blank">
                                    {{ $t('home.footer.audioTransmission') }} </a>
                                <!-- <a class="hover:opacity-75" href="#"> Video-Übersetzung </a>
                                <a class="hover:opacity-75" href="#"> Mobile App </a> -->
                            </nav>
                        </div>
                        <div>
                            <p class="font-medium">{{ $t('home.footer.helpCenter') }}</p>
                            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
                                <a class="hover:opacity-75" href="/faq" target="_blank"> {{ $t('home.footer.faq') }}
                                </a>
                                <a class="hover:opacity-75" href="#"> {{ $t('home.footer.contact') }} </a>
                                <!-- <a class="hover:opacity-75" href="#"> Live Chat </a>
                                <a class="hover:opacity-75" href="#"> Ressourcen </a> -->
                            </nav>
                        </div>
                        <div>
                            <p class="font-medium">{{ $t('home.footer.legal') }}</p>
                            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
                                <a class="hover:opacity-75" href="/privacy-policy" target="_blank"> {{
                                    $t('home.footer.privacyPolicy') }} </a>
                                <a class="hover:opacity-75" href="/terms-and-conditions" target="_blank"> {{
                                    $t('home.footer.termsAndConditions') }} </a>
                                <a class="hover:opacity-75" href="/cookie-policy" target="_blank"> {{
                                    $t('home.footer.cookiePolicy') }}
                                </a>
                                <!-- <a class="hover:opacity-75" href="#"> Sicherheit </a> -->
                            </nav>
                        </div>
                    </div>
                </div>
                <p class="mt-8 text-xs text-gray-500">
                    {{ $t('home.footerCopyright') }}
                </p>
            </div>
        </footer>
    </div>
    <!-- Login Modal -->
    <transition name="fade">
        <div v-if="showLoginModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="login-modal" @click="showLoginModal = false">
            <div class="relative top-20 mx-auto p-5 border w-80 md:w-96 shadow-lg rounded-md bg-white" @click.stop>
                <LoginModal @close="showLoginModal = false" @success="handleLoginSuccess"
                    @switch-to-signup="switchToSignup" @reset-password="resetPassword" />
            </div>
        </div>
    </transition>
    <!-- Signup Modal -->
    <transition name="fade">
        <div v-if="showSignupModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="signup-modal" @click="showSignupModal = false">
            <div class="relative top-20 mx-auto p-5 border w-80 md:w-96 shadow-lg rounded-md bg-white" @click.stop>
                <SignupModal @close="showSignupModal = false" @success="handleLoginSuccess"
                    @switch-to-login="switchToLogin" />
            </div>
        </div>
    </transition>
</template>

<script>
import { supportedLanguages } from '../languages/i18n';
import { GlobeAltIcon, CurrencyDollarIcon, UserGroupIcon, PuzzlePieceIcon } from '@heroicons/vue/24/outline'
import PricingTable from './PricingTable.vue';
import PriceCalculator from './PriceCalculator.vue';
import LoginModal from './LoginModal.vue';
import SignupModal from './SignupModal.vue';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseInit';

export default {
    components: {
        GlobeAltIcon, CurrencyDollarIcon, UserGroupIcon, PuzzlePieceIcon, PricingTable, PriceCalculator, LoginModal, SignupModal
    },
    data() {
        return {
            isLanguageMenuOpen: false,
            isMobileMenuOpen: false,
            showLoginModal: false,
            showSignupModal: false,
            goToCheckout: false,
            checkoutOptions: {
                plan: null,
                isAnnual: false,
            },
            supportedLanguages,
            testimonials: [
                {
                    quote: 'Streamlingo hat unsere internationale Konferenz transformiert. Die Qualität der Übersetzungen und die Benutzerfreundlichkeit haben uns und unsere Teilnehmer begeistert.',
                    name: 'Pastor Michael Schmidt',
                    position: 'Internationale Christliche Gemeinde Berlin',
                    avatar: require('@/assets/avatar2.svg')
                },
                {
                    quote: 'Dank Streamlingo können wir nun Gottesdienste in fünf verschiedenen Sprachen anbieten, ohne unser Budget zu sprengen. Ein Segen für unsere vielfältige Gemeinschaft.',
                    name: 'Sarah Mueller',
                    position: 'Gemeindekoordinatorin, Evangelische Kirche München',
                    avatar: require('@/assets/avatar1.svg')
                }
            ],
            faqStates: [false, false, false],
        }
    },
    computed: {
        benefits() {
            return [
                {
                    title: this.$t('home.benefits.boundlessCommunication.title'),
                    description: this.$t('home.benefits.boundlessCommunication.description'),
                    icon: 'GlobeAltIcon'
                },
                {
                    title: this.$t('home.benefits.aiPrecision.title'),
                    description: this.$t('home.benefits.aiPrecision.description'),
                    icon: 'PuzzlePieceIcon'
                },
                {
                    title: this.$t('home.benefits.costEfficient.title'),
                    description: this.$t('home.benefits.costEfficient.description'),
                    icon: 'CurrencyDollarIcon'
                },
                {
                    title: this.$t('home.benefits.tailoredForCommunity.title'),
                    description: this.$t('home.benefits.tailoredForCommunity.description'),
                    icon: 'UserGroupIcon'
                }
            ]
        },
        steps() {
            return [
                {
                    title: this.$t('home.howItWorks.step1.title'),
                    description: this.$t('home.howItWorks.step1.description'),
                },
                {
                    title: this.$t('home.howItWorks.step2.title'),
                    description: this.$t('home.howItWorks.step2.description'),
                },
                {
                    title: this.$t('home.howItWorks.step3.title'),
                    description: this.$t('home.howItWorks.step3.description'),
                },
                {
                    title: this.$t('home.howItWorks.step4.title'),
                    description: this.$t('home.howItWorks.step4.description'),
                }
            ]
        },
        faqsTranslations() {
            return [
                {
                    question: this.$t('home.faq.question1.question'),
                    answer: this.$t('home.faq.question1.answer'),
                },
                {
                    question: this.$t('home.faq.question2.question'),
                    answer: this.$t('home.faq.question2.answer'),
                },
                {
                    question: this.$t('home.faq.question3.question'),
                    answer: this.$t('home.faq.question3.answer'),
                }
            ]
        },
        faqs() {
            return this.faqsTranslations.map((faq, index) => ({
                ...faq,
                isOpen: this.faqStates[index]
            }))
        },
    },
    methods: {
        async handleSubscribe(plan, isAnnual) {
            if (plan === "Free") {
                if (this.$store.state.user) this.$router.push("/dashboard");
                else this.showSignupModal = true;
                return;
            }
            if (this.$store.state.user) {
                await this.$store.dispatch('checkoutPlan', { plan, isAnnual, cancelUrl: this.$route.path })
            } else {
                this.checkoutOptions.plan = plan;
                this.checkoutOptions.isAnnual = isAnnual;
                this.goToCheckout = true;
                this.showSignupModal = true;
            }
        },
        async handleLoginSuccess() {
            this.showSignupModal = false;
            this.showLoginModal = false;
            if (this.goToCheckout) {
                await this.$store.dispatch('checkoutPlan', { plan: this.checkoutOptions.plan, isAnnual: this.checkoutOptions.isAnnual, cancelUrl: this.$route.path });
                this.goToCheckout = false;
            } else {
                this.$router.push("/dashboard");
            }
        },
        switchToSignup() {
            this.showLoginModal = false;
            this.showSignupModal = true;
        },
        switchToLogin() {
            this.showSignupModal = false;
            this.showLoginModal = true;
        },
        async resetPassword(email) {
            try {
                await sendPasswordResetEmail(auth, email);
                this.$store.dispatch('showSnackbar', { message: this.$t('login.resetPasswordMail') });
            } catch (error) {
                this.$store.dispatch('showSnackbar', { message: this.$t('login.resetPasswordMailError') });
            }
        },
        changeLanguage(language) {
            this.$i18n.locale = language.locale;
            this.isLanguageMenuOpen = false;
        },
        getCurrentLanguageFlag(locale = this.$i18n.locale) {
            return require(`@/assets/flags/${locale}.svg`);
        },
        closeLanguageMenu(event) {
            if (!this.$refs.languageMenu.contains(event.target)) {
                this.isLanguageMenuOpen = false;
            }
        },
        toggleFAQ(index) {
            this.faqStates[index] = !this.faqStates[index];
        },
        checkMobileDevice() {
            if (window.innerWidth <= 1024) {
                this.isMobileMenuOpen = false;
            } else {
                this.isMobileMenuOpen = true;
            }
        },
        scrollToSection(sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                const startPosition = window.pageYOffset;
                const navbarHeight = this.getNavbarHeight(); // Neue Methode zur Bestimmung der Navbar-Höhe
                const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - navbarHeight + 50;
                const distance = targetPosition - startPosition;
                const duration = 1000; // Dauer der Animation in Millisekunden
                let start = null;

                const step = (timestamp) => {
                    if (!start) start = timestamp;
                    const progress = timestamp - start;
                    window.scrollTo(0, this.easeInOutCubic(progress, startPosition, distance, duration));
                    if (progress < duration) window.requestAnimationFrame(step);
                };

                window.requestAnimationFrame(step);
            }
            this.isMobileMenuOpen = false;
        },
        easeInOutCubic(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t * t + b;
            t -= 2;
            return c / 2 * (t * t * t + 2) + b;
        },
        getNavbarHeight() {
            const navbar = document.querySelector('nav'); // Passen Sie den Selektor an Ihre Navbar-Struktur an
            return navbar ? navbar.offsetHeight : 0;
        },
    },
    mounted() {
        this.checkMobileDevice();
        window.addEventListener('resize', this.checkMobileDevice);
        document.addEventListener('click', this.closeLanguageMenu);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobileDevice);
        document.removeEventListener('click', this.closeLanguageMenu);
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>