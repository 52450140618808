<template>
  <v-app>
    <!-- Topbar -->
    <div class="flex absolute right-28 mt-4">
      <div class="hover:cursor-pointer hover:bg-slate-200 md:px-6 pt-2 pb-2 ma-1 mb-2 rounded-xl text-indigo-600"
        @click="$router.push('/dashboard')" v-if="$store.state.user">
        Dashboard
      </div>
      <div class="hover:cursor-pointer hover:bg-slate-200  md:px-6 pt-2 pb-2 ma-1 mb-2 rounded-xl text-indigo-600"
        @click="showLogInModal = true" v-if="!$store.state.user">
        Log In
      </div>
      <div class="hover:cursor-pointer hover:bg-slate-200 px-6 pt-2 pb-2 ma-1 mb-2 rounded-xl text-indigo-600"
        @click="showSignUpModal = true" v-if="!$store.state.user">
        Sign Up
      </div>
      <!-- Sprachauswahl -->
      <div>
        <button @click="changeLanguage('de')"
          class="hover:cursor-pointer hover:bg-slate-200 md:px-6 pt-2 pb-2 ma-1 mb-2 rounded-xl text-indigo-600 flex"
          v-if="$i18n.locale === 'en'">
          <v-img :src="require('@/assets/web.svg')" class="w-5 mt-[2px] mr-1"></v-img>
          DE
        </button>
        <button @click="changeLanguage('en')"
          class="hover:cursor-pointer hover:bg-slate-200 md:px-6 pt-2 pb-2 ma-1 mb-2 rounded-xl text-indigo-600 flex"
          v-else> <v-img :src="require('@/assets/web.svg')" class="w-5 mt-[2px] mr-1"></v-img>
          EN</button>
      </div>
    </div>

    <v-main>
      <!-- Hero Section -->
      <v-container class="fill-height set-max-height" fluid>
        <v-row align="center" justify="center">
          <!-- Flex-Container für Logo und Text. Wir nutzen Flexbox direkt in v-row. -->
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <!-- Logo auf der linken Seite -->
            <v-img :src="require('@/assets/logo.svg')" class="mr-3" contain height="200"></v-img>

            <!-- Text auf der rechten Seite, flex-grow-1 sorgt dafür, dass der Text den verbleibenden Platz einnimmt -->
            <div class="flex-grow-1">
              <h1 class="responsive-text-h1 mb-3 mt-4">{{ $t('welcome_to') }}</h1>
              <h1 class="responsive-text-h1 mb-3">{{ $t('streamlingo') }}</h1>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" class="text-left">
            <h2 class="responsive-text-h2 mb-6"><strong>{{ $t('building_bridges') }}</strong></h2>
            <h2 class="responsive-text-h6">{{ $t('in_a_world') }}</h2>
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-container>


      <!-- Inhalt Section -->
      <v-container class="fill-height set-max-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <h2 class="responsive-text-h2 mb-10 mt-10">{{ $t('why_streamlingo') }}</h2>
          </v-col>
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <div class="flex-grow-1">
              <p class="responsive-text-h5 ml-5 mt-10 mb-7">{{ $t('with_streamlingo') }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="10" class="d-flex align-center">
            <v-list lines="two">
              <v-list-item prepend-icon="mdi-translate">
                <v-list-item-content>
                  <v-list-item-title class="responsive-text-h6">{{ $t('real_time') }}</v-list-item-title>
                  <v-list-item-subtitle class="responsive-text-h6">{{ $t('real_time_subtitles')
                    }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item prepend-icon="mdi-earth">
                <v-list-item-content>
                  <v-list-item-title class="responsive-text-h6">{{ $t('language_support') }}</v-list-item-title>
                  <v-list-item-subtitle class="responsive-text-h6">{{ $t('language_support_subtitles')
                    }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item prepend-icon="mdi-thumb-up-outline">
                <v-list-item-content>
                  <v-list-item-title class="responsive-text-h6">{{ $t('user_friendly') }}</v-list-item-title>
                  <v-list-item-subtitle class="responsive-text-h6">{{ $t('user_friendly_subtitles') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-container>

      <!-- Streamlingo Testen -->
      <v-container class="fill-heigt" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <h2 class="responsive-text-h3 mb-6 mt-10">{{ $t('no_boudaries') }}</h2>
          </v-col>
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <div class="flex-grow-1">
              <p class="responsive-text-h5 ml-5 mb-7">{{ $t('no_boudaries_subtitles') }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <h2 class="responsive-text-h3 mb-6 mt-10">{{ $t('test_today') }}</h2>
          </v-col>
          <v-col cols="12" md="10" class="d-flex align-center justify-start">
            <div class="flex-grow-1">
              <p class="responsive-text-h5 ml-5 mb-7">{{ $t('no_limits') }}</p>
              <p class="responsive-text-h5 ml-5 mb-7">
                <span v-html="$t('test_today_long')"></span>
                <a class="w-40 h-9 inline-flex justify-center rounded-lg bg-indigo-500 py-1 text-lg font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                  @click="showSignUpModal = true">{{ $t('test_now_button') }}</a>
              </p>
              <p class="responsive-text-h5 ml-5 mb-7">{{ $t('questions_left_1') }}
                <a class="text-indigo-500 cursor-pointer" @click="$router.push('/faq')">{{ $t('questions_left_2')
                  }}</a>.
              </p>
            </div>
          </v-col>

        </v-row>
        <v-row></v-row>
      </v-container>

      <!-- Pricing Section -->
      <PricingTable style="width: 80%;" class="mx-auto mt-6" @subscribe="handleSubscribe"></PricingTable>
      <PriceCalculator class="mx-auto mt-6"></PriceCalculator>


      <!-- Footer -->
      <v-footer dark padless>
        <v-col class="text-center" cols="12">
          <v-divider class="my-4"></v-divider>
          <p @click="$router.push('/impressum')" class="cursor-pointer" v-html="$t('imprint')"></p>
        </v-col>
      </v-footer>
    </v-main>

    <v-dialog v-model="showSignUpModal" class="responsive-modal">
      <SignupModal @success="routingfterLogin" @changeToLogin="changeToLogin"></SignupModal>
    </v-dialog>
    <v-dialog v-model="showLogInModal" class="responsive-modal">
      <LoginModal @success="routingfterLogin" @changeToSignUp="changeToSignUp"
        @resetPassword="resetPassword"></LoginModal>
    </v-dialog>
  </v-app>
</template>

<script>
import SignupModal from './SignupModal';
import LoginModal from './LoginModal.vue';
import PricingTable from './PricingTable.vue';
import PriceCalculator from './PriceCalculator.vue';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseInit';

export default {
  components: {
    SignupModal,
    LoginModal,
    PricingTable,
    PriceCalculator,
  },
  data() {
    return {
      showSignUpModal: false,
      showLogInModal: false,
      goToCheckout: false,
      checkoutOptions: {
        plan: null,
        isAnnual: false,
      },
    };
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
    async resetPassword(email) {
      try {
        await sendPasswordResetEmail(auth, email);
        this.$store.dispatch('showSnackbar', { message: this.$t('login.resetPasswordMail') });
      } catch (error) {
        this.$store.dispatch('showSnackbar', { message: this.$t('login.resetPasswordMailError') });
      }
    },
    async handleSubscribe(plan, isAnnual) {
      if (plan === "Free") {
        if (this.$store.state.user) this.$router.push("/dashboard");
        else this.showSignUpModal = true;
        return;
      }
      if (this.$store.state.user) {
        await this.$store.dispatch('checkoutPlan', { plan, isAnnual, cancelUrl: this.$route.path })
      } else {
        this.checkoutOptions.plan = plan;
        this.checkoutOptions.isAnnual = isAnnual;
        this.goToCheckout = true;
        this.showSignUpModal = true;
      }
    },
    async routingfterLogin() {
      this.showSignUpModal = false;
      this.showLogInModal = false;
      if (this.goToCheckout) {
        await this.$store.dispatch('checkoutPlan', { plan: this.checkoutOptions.plan, isAnnual: this.checkoutOptions.isAnnual, cancelUrl: this.$route.path });
        this.goToCheckout = false;
      } else {
        this.$router.push("/dashboard");
      }
    },
    changeToSignUp() {
      this.showLogInModal = false;
      this.showSignUpModal = true;
    },
    changeToLogin() {
      this.showSignUpModal = false;
      this.showLogInModal = true;
    },
  },
}
</script>

<style>
.set-max-height {
  max-height: 100vh !important;
  overflow-y: auto;
}

/* Mobile Geräte */
@media (max-width: 599px) {
  .responsive-modal {
    max-width: 330px !important;
  }

  .responsive-card {
    width: 330px !important;
  }

  .responsive-text-h1 {
    font-size: 2rem !important;
    font-weight: 300;
    line-height: 2rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h2 {
    font-size: 1.75rem !important;
    font-weight: 300;
    line-height: 1.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h3 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h5 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h6 {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 0.75rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
}

/* Tablet und Desktop Geräte */
@media (min-width: 600px) {
  .responsive-modal {
    max-width: 500px !important;
  }

  .responsive-card {
    width: 60% !important;
  }

  .responsive-text-h1 {
    font-size: 3rem !important;
    font-weight: 300;
    line-height: 3rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h2 {
    font-size: 2.25rem !important;
    font-weight: 300;
    line-height: 2.25rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h3 {
    font-size: 2rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h5 {
    font-size: 1.25rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h6 {
    font-size: 1rem !important;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
}

/* Große Desktop Bildschirme */
@media (min-width: 960px) {
  .responsive-card {
    width: 60% !important;
  }

  .responsive-text-h1 {
    font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h2 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h3 {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }

  .responsive-text-h6 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
}
</style>
