<template>
  <v-app>
    <v-main>
      <div class="flex flex-row divide-x divide-slate-300 h-max">
        <SidebarComponent v-if="isSidebarOpen" class="w-[300px] flex-shrink-0" />
        <div class="flex-grow">
          <router-view />
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" location="top" color="white">
        <p v-html="snackbarMessage"></p>
        <template v-slot:actions>
          <v-btn color="pink" variant="text" @click="snackbar = false">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <!-- QR-Code-Modal -->
      <v-dialog v-model="showQRModal" class="w-4/5 max-w-[350px]">
        <v-card>
          <v-card-title class="text-center">{{ qrCodeText }}</v-card-title>
          <v-card-text class="flex justify-center">
            <vue-qrcode :text="qrCodeLink"></vue-qrcode>
          </v-card-text>
          <div class="flex justify-center">
            <a :href="qrCodeLink" target="_blank" rel="noopener noreferrer"
              class="text-sky-600 text-sm md:text-base text-center w-5/6">
              {{ qrCodeLink }}</a>
          </div>
          <v-card-actions>
            <v-btn color="primary text-sm md:text-base" text @click="showQRModal = false"> {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import SidebarComponent from './components/SidebarComponent.vue'
import VueQrcode from 'vue-qrcode-component';

export default {
  components: {
    SidebarComponent,
    VueQrcode,
  },
  data() {
    return {
      snackbar: false,
      showQRModal: false,
    }
  },
  watch: {
    snackbarState() {
      this.snackbar = this.snackbarState
    },
    snackbar() {
      this.$store.commit('setSnackbar', this.snackbar)
    },
    qrCodeState() {
      this.showQRModal = this.qrCodeState
    },
    showQRModal() {
      this.$store.commit('setShowQRModal', this.showQRModal)
    },
  },
  computed: {
    isSidebarOpen() {
      return this.$route.path !== '/' && this.$route.name !== 'listen' && this.$route.name !== 'speaker' &&
        this.$route.name !== 'mobileDashboard' && this.$route.name !== 'agb' && this.$route.name !== 'impressum' &&
        (this.$route.name !== 'faq' || this.$store.state.user) && this.$route.path !== '/2' && this.$route.path !== '/company'
        && this.$route.path !== '/services' && this.$route.path !== '/privacy-policy' && this.$route.path !== '/cookie-policy'  
    },
    snackbarState() {
      return this.$store.state.snackbar
    },
    snackbarMessage() {
      return this.$store.state.snackbarMessage
    },
    snackbarTimeout() {
      return this.$store.state.snackbarTimeout || 5000
    },
    qrCodeState() {
      return this.$store.state.showQRModal
    },
    qrCodeLink() {
      return this.$store.state.qrCodeLink
    },
    qrCodeText() {
      return this.$store.state.qrCodeText
    },
  },
}
</script>

<style>
/* Für WebKit-Browser wie Chrome, Safari und Opera */
::-webkit-scrollbar {
  width: 8px;
  /* Breite des Scrollbalkens */
}

::-webkit-scrollbar-track {
  background-color: #e0dfdfc4;
  /* Farbe der Scrollbalken-Spur */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Farbe des Scrollbalken-Daumens */
  border-radius: 6px;
  /* Abrundung der Ecken des Daumens */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Farbe des Daumens beim Hover */
}
</style>