<template>
    <div v-if="show" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">{{ $t('warningModal.title') }}</h3>
          <div class="mt-2 px-7 py-3">
            <p class="text-sm text-gray-500">
              {{ $t('warningModal.message', { minutes: remainingMinutes }) }}
            </p>
          </div>
          <div class="mt-4 flex justify-center">
            <button @click="continueTranslation" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
              {{ $t('warningModal.continue') }}
            </button>
            <button @click="stopTranslation" class="ml-3 px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300">
              {{ $t('warningModal.stop') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WarningModal',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      remainingMinutes: {
        type: Number,
        required: true
      }
    },
    methods: {
      continueTranslation() {
        this.$emit('continue');
      },
      stopTranslation() {
        this.$emit('stop');
      }
    }
  }
  </script>