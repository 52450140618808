<template>
    <div class="listenerview-container">
        <div class="content">
            <img class="logo-mit-schrift-top" alt="Logo mit schrift" src="@/assets/Logo mit schrift.png"
                @click="$router.push('/')" style="cursor: pointer;" />
            <div class="header-wrapper">
                <div class="text-wrapper-2">{{ conferenceName }}</div>
            </div>
            <div class="playing-buttons">
                <div v-for="(button, index) in displayedButtons" :key="button.id">
                    <LanguageButton :language="button.channelName" :isPlaying="button.isPlaying"
                        :isDeactivated="isButtonDeactivated(button.id)" @click="handleButtonClick(button, index)"
                        :style="{ marginTop: '2rem' }" />
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="text-wrapper-4">Live audio translation by</div>
            <img class="logo-mit-schrift" alt="Logo mit schrift" src="@/assets/Logo mit schrift.png"
                @click="$router.push('/')" style="cursor: pointer;" />
        </div>
    </div>
</template>

<script>
import LanguageButton from './LanguageButton.vue';
import { ref, onValue, get, child } from 'firebase/database';
import { rtdb, functions } from '@/firebaseInit';
import { httpsCallable } from 'firebase/functions';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { markRaw } from 'vue';


export default {
    components: {
        LanguageButton,
    },
    data() {
        return {
            conferenceId: null,
            recordingStatus: "ended",
            audioUrls: [],
            currentAudio: null,
            conferenceName: '',
            displayedButtons: [],
            originalAudio: null,
            currentAudioIndex: 0,
            isAudioPlaying: false,
            channelIdIsPlaying: null,
            channels: [],
            client: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            uid: Math.floor(Math.random() * 100000),
            generateRtcToken: httpsCallable(functions, 'generateRtcToken'),
            changeListenerCount: httpsCallable(functions, 'changeListenerCount'),
        };
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    async created() {
        const conferenceUrl = this.$route.params.conferenceUrl;
        const snapshot = await get(child(ref(rtdb), `urlMapping/${conferenceUrl}/conferenceId`));
        this.conferenceId = snapshot.val() ? snapshot.val() : conferenceUrl;

        this.watchRecordingStatus();
        this.loadInitialHistoricalUrls();
        this.setupCurrentUrlListener();
        this.generateRtcToken({ reduceColdStart: true });
        this.changeListenerCount({ reduceColdStart: true });
    },
    watch: {
        recordingStatus: {
            handler: async function (newVal) {
                if (newVal === 'ended') {
                    if (this.isAudioPlaying) {
                        await this.stopAudio();
                        this.isAudioPlaying = false;
                        this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
                    }
                    this.audioUrls = [];
                }
            },
        },

    },
    methods: {
        watchRecordingStatus() {
            const statusRef = ref(rtdb, `conferences/${this.conferenceId}`);
            onValue(statusRef, async (snapshot) => {
                if (snapshot.exists()) {
                    this.conferenceName = snapshot.val().conferenceName;
                    this.recordingStatus = snapshot.val().recordingStatus;

                    const newChannels = snapshot.val().channels || [];
                    const areChannelsEqual = JSON.stringify(this.channels) === JSON.stringify(newChannels);
                    if (!areChannelsEqual) {
                        if (this.isAudioPlaying) {
                            const isCurrentActiveChannel= this.channels.find(channel => channel.id === this.channelIdIsPlaying);
                            const newCurrentActiveChannel = newChannels.find(channel => channel.id === this.channelIdIsPlaying);
                            if (JSON.stringify(isCurrentActiveChannel) === JSON.stringify(newCurrentActiveChannel)) {
                                this.channels = newChannels;
                                this.updateDisplayedButtons();
                                // setzte channel mit this.channelIdIsPlaying auf isPlaying
                                this.displayedButtons = this.displayedButtons.map(button => ({
                                    ...button,
                                    isPlaying: button.id === this.channelIdIsPlaying,
                                }));
                            } else {
                                await this.stopAudio();
                                this.isAudioPlaying = false;
                                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
                                this.channelIdIsPlaying = null;
                                this.channels = newChannels;
                                this.updateDisplayedButtons();

                            }
                        } else {
                            this.channels = newChannels;
                            this.updateDisplayedButtons();
                        }
                    }
                } else {
                    console.log('Keine Daten verfügbar');
                }
            }, (error) => {
                console.error(error);
            });
        },
        loadInitialHistoricalUrls() {
            if (this.recordingStatus === 'ended') return; // Wenn die Aufnahme beendet ist, werden keine historischen URLs benötigt
            const historicalUrlsRef = ref(rtdb, `translations/${this.conferenceId}/historicalUrls`);
            get(historicalUrlsRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const allUrls = snapshot.val();
                    Object.keys(allUrls).forEach(language => {
                        // Extrahiere die URL-Werte aus den Objekten und speichere sie in einem Array
                        const urlArray = Object.values(allUrls[language]).map(item => item);
                        this.audioUrls[language] = urlArray;
                    });
                } else {
                    // Keine historischen URLs gefunden, leere Struktur initialisieren
                    Object.keys(this.audioUrls).forEach(language => {
                        this.audioUrls[language] = [];
                    });
                }
            }).catch((error) => {
                console.error('Error loading historical URLs:', error);
            });
        },
        setupCurrentUrlListener() {
            const currentUrlRef = ref(rtdb, `translations/${this.conferenceId}/currentUrl`);
            onValue(currentUrlRef, (snapshot) => {
                if (snapshot.exists() && this.recordingStatus !== 'ended') {
                    const currentUrls = snapshot.val();
                    Object.keys(currentUrls).forEach(language => {
                        const newUrl = currentUrls[language];
                        if (!this.audioUrls[language]) {
                            this.audioUrls[language] = [];
                        }
                        const lastUrl = this.audioUrls[language].length > 0 ? this.audioUrls[language][this.audioUrls[language].length - 1] : null;
                        if (newUrl !== lastUrl) {
                            this.audioUrls[language].push(newUrl);
                        }
                    });
                }
            });
        },
        updateDisplayedButtons() {
            this.displayedButtons = this.channels
                .filter(channel => channel.isActive)
                .map(channel => ({
                    id: channel.id,
                    channelName: channel.channelName,
                    isPlaying: channel.isPlaying ? channel.isPlaying : false,
                    useAI: channel.useAI,
                    isMuted: channel.isMuted,
                    speakerStreamCode: channel.speakerStreamCode,
                    targetLanguage: channel.targetLanguage,
                }));
        },
        isButtonDeactivated(buttonId) {
            const button = this.displayedButtons.find(b => b.id === buttonId);
            if (button.useAI) {
                return (
                    (this.recordingStatus !== 'running' && this.currentAudio === null) ||
                    !this.audioUrls[button.targetLanguage.code] ||
                    this.audioUrls[button.targetLanguage.code].length === 0
                );
            } else {
                return button.isMuted || this.recordingStatus === 'ended'; // Überprüfe isMuted für nicht-AI-Buttons
            }
        },
        async handleButtonClick(button, index) {
            await this.stopAudio();

            if (button.useAI && this.isButtonDeactivated(button.id)) return;
            if (!button.useAI && button.isMuted) return;

            if (this.isAudioPlaying) {
                this.isAudioPlaying = false;
                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
                this.channelIdIsPlaying = null;
                return;
            }
            this.isAudioPlaying = true;
            this.channelIdIsPlaying = button.id;
            this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: true });

            if (button.useAI) {
                this.playFromPenultimate(button.targetLanguage.code, index);
            } else {
                const isPublisher = false;
                const channelName = button.speakerStreamCode.toString();
                const response = await this.generateRtcToken({ channelName: channelName, isPublisher: isPublisher })
                    .catch((error) => { console.error('Error:', error.message); });
                await this.client.join(response.data.appId, channelName, response.data.token, response.data.uid);

                this.client.on("user-published", async (user, mediaType) => {
                    await this.client.subscribe(user, mediaType);
                    if (mediaType === "audio") {
                        const audioTrack = user.audioTrack;
                        audioTrack.play();
                    }
                });

                this.displayedButtons = this.displayedButtons.map((button, buttonIndex) => ({
                    ...button,
                    isPlaying: buttonIndex === index,
                }));
            }

        },
        async playFromPenultimate(languageCode, index) {
            let languageObj = this.audioUrls[languageCode];
            if (!languageObj || languageObj.length === 0) return;
            if (languageObj.length >= 2) {
                this.currentAudioIndex = languageObj.length - 2;
            } else {
                this.currentAudioIndex = 0;
            }

            this.displayedButtons = this.displayedButtons.map((button, buttonIndex) => ({
                ...button,
                isPlaying: buttonIndex === index,
            }));

            this.playNextAudio(languageCode);
        },
        async playNextAudio(languageCode) {
            let languageObj = this.audioUrls[languageCode];
            if (this.currentAudioIndex >= languageObj.length) {
                if (this.recordingStatus !== 'ended') {
                    // Wenn keine weiteren Audiodateien verfügbar sind, aber die Live-Übersetzung aktiv ist, spiele das stumme Audio ab
                    this.currentAudio = new Audio(this.$SILENT_AUDIO_URL);
                    this.currentAudio.play();
                    this.currentAudio.onended = () => {
                        // Versuche erneut, das nächste Audio abzuspielen, nachdem das stumme Audio beendet ist
                        this.playNextAudio(languageCode);
                    };
                } else {
                    await this.stopAudio();
                }
                return;
            }

            this.currentAudio = new Audio(languageObj[this.currentAudioIndex]);
            this.currentAudio.play();
            this.currentAudio.onended = () => {
                this.currentAudioIndex++;
                this.playNextAudio(languageCode); // Spiele das nächste Audio ab, wenn das aktuelle beendet ist
            };
            this.currentAudio.onerror = () => {
                console.error('Fehler beim Abspielen der Audiodatei');
                this.currentAudioIndex++;
                this.playNextAudio(languageCode); // Versuche, das nächste Audio abzuspielen, wenn ein Fehler auftritt
            };
        },
        async stopAudio() {
            if (this.currentAudio) {
                this.currentAudio.pause(); // Stoppt die Wiedergabe des aktuellen Audios
                this.currentAudio.currentTime = 0; // Setzt die aktuelle Wiedergabezeit auf den Anfang zurück
                this.currentAudio = null; // Entfernt die Referenz auf das aktuelle Audio-Objekt
            }
            this.currentAudioIndex = 0; // Zurücksetzen des Index für das nächste Mal

            this.displayedButtons = this.displayedButtons.map(button => ({
                ...button,
                isPlaying: false,
            }));

            await this.client.leave();
        },
        fastStopAudio() {
            if (this.currentAudio) {
                this.currentAudio.pause(); // Stoppt die Wiedergabe des aktuellen Audios
                this.currentAudio.currentTime = 0; // Setzt die aktuelle Wiedergabezeit auf den Anfang zurück
                this.currentAudio = null; // Entfernt die Referenz auf das aktuelle Audio-Objekt
            }
            this.currentAudioIndex = 0; // Zurücksetzen des Index für das nächste Mal

            this.displayedButtons = this.displayedButtons.map(button => ({
                ...button,
                isPlaying: false,
            }));

            this.client.leave();
        },
        handleBeforeUnload() {
            console.log('ListenerView beforeunload')
            if (this.isAudioPlaying) {
                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
                this.stopAudio();
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
};
</script>

<style>
.listenerview-container {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.listenerview-container .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow: auto;
}

.listenerview-container .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.listenerview-container .text-wrapper-2 {
    color: #000000;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
}

.listenerview-container .playing-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listenerview-container .footer {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.listenerview-container .text-wrapper-4 {
    color: #000000;
    font-family: "Inter-Light", Helvetica;
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 5px;
}

.listenerview-container .logo-mit-schrift-top {
    height: 61px;
    width: 279px;
    margin-bottom: 2rem;
}

.listenerview-container .logo-mit-schrift {
    height: 33px;
    width: 159px;
}
</style>