<template>
    <div class="speaker-page-component">
        <div class="content">
            <div class="header-wrapper">
                <div class="text-normal">{{ conferenceName }}</div>
            </div>
            <div class="listen-speak-wrapper"> <img class="icons" alt="headphones" src="@/assets/headphones.svg" />
                <span class="text-normal">Listen</span>
            </div>
            <LanguageButton :language="originalName" :isPlaying="isOriginalPlaying"
                :isDeactivated="recordingStatus === 'ended'" @click="handleOriginalClick" style="margin-bottom: 1rem" />
            <audio ref="remoteAudio" autoplay></audio>
            <div class="listen-speak-wrapper"> <img class="icons" alt="microphone" src="@/assets/microphone.svg" />
                <span class="text-normal">Speak</span>
            </div>
            <SpeakerComponent v-if="isMuted" :language="ownName" :isDeactivated="recordingStatus === 'ended'"
                @start="startSpeaking" :isLoading="isLoading" />
            <SpeakerComponentRunning v-else :language="ownName" @mute="stopSpeaking" :isLoading="isLoading" />
        </div>
        <div class="footer">
            <div class="text-wrapper-4">Live audio translation by</div> <img class="logo-mit-schrift"
                alt="Logo mit schrift" src="@/assets/Logo mit schrift.png" />
        </div>
    </div>
</template>
<script>
import SpeakerComponent from './SpeakerComponent.vue';
import SpeakerComponentRunning from './SpeakerComponentRunning.vue';
import LanguageButton from './LanguageButton.vue';
import { ref, get, child, onValue } from 'firebase/database';
import { rtdb, functions } from '@/firebaseInit';
import { httpsCallable } from 'firebase/functions';
import AgoraRTC from "agora-rtc-sdk-ng";
import { markRaw } from "vue";

export default {
    components: {
        LanguageButton,
        SpeakerComponent,
        SpeakerComponentRunning,
    }, data() {
        return {
            conferenceName: '',
            originalName: '',
            originalStreamCode: '',
            ownStreamCode: '',
            ownName: '',
            speakerId: '',
            isOriginalPlaying: false,
            isMuted: true,
            listeningClient: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            speakingClient: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            localAudioTrack: null,
            conferenceId: '',
            recordingStatus: '',
            setMutedForSpeaker: httpsCallable(functions, 'setMutedForSpeaker'),
            generateRtcToken: httpsCallable(functions, 'generateRtcToken'),
            changeListenerCount: httpsCallable(functions, 'changeListenerCount'),
        };
    },
    async created() {
        this.speakerId = this.$route.params.speakerId;
        this.getSpeakerData();
        this.setMutedForSpeaker({ reduceColdStart: true });
        this.generateRtcToken({ reduceColdStart: true });
        this.changeListenerCount({ reduceColdStart: true });
    },
    watch: {
        recordingStatus() {
            if (this.recordingStatus === 'ended') {
                if (this.isOriginalPlaying) {
                    this.changeListenerCount({ conferenceId: this.conferenceId, channelId: 0, increase: false });
                    this.listeningClient.leave();
                    this.isOriginalPlaying = false;
                }
                if (!this.isMuted) {
                    this.stopSpeaking();
                }
            }
        },
    },
    methods: {
        async setMuted(value) {
            await this.setMutedForSpeaker({ conferenceId: this.conferenceId, speakerStreamCode: this.speakerId, isMuted: value })
                .catch((error) => { console.error('Error:', error.message); });
            this.isMuted = value;
        },
        getSpeakerData() {
            get(child(ref(rtdb), `speaker/${this.speakerId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    this.conferenceId = data.conferenceId;
                    this.originalName = data.originalName;
                    this.originalStreamCode = data.originalStreamCode;
                    this.ownStreamCode = data.ownStreamCode;
                    this.ownName = data.ownName;
                    this.conferenceId = data.originalStreamCode;

                    this.watchConferenceStatus();
                } else console.log('Keine Daten verfügbar');
            }).catch((error) => console.error(error));
        },
        watchConferenceStatus() {
            const statusRef = ref(rtdb, `conferences/${this.conferenceId}`);
            onValue(statusRef, (snapshot) => {
                if (snapshot.exists()) {
                    this.conferenceName = snapshot.val().conferenceName;
                    this.recordingStatus = snapshot.val().recordingStatus;
                } else console.log('Keine Daten verfügbar');
            }, (error) => console.error(error));
        },
        async handleOriginalClick() {
            if (this.isOriginalPlaying) {
                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: 0, increase: false });
                await this.listeningClient.leave();
                this.isOriginalPlaying = false;
            } else {
                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: 0, increase: true });
                const isPublisher = false;
                const channelname = this.originalStreamCode.toString();
                const response = await this.generateRtcToken({ channelName: channelname, isPublisher: isPublisher }).catch((error) => { console.error('Error:', error.message); });
                await this.listeningClient.join(response.data.appId, channelname, response.data.token, response.data.uid);

                this.listeningClient.on("user-published", async (user, mediaType) => {
                    await this.listeningClient.subscribe(user, mediaType);
                    if (mediaType === "audio") {
                        const audioTrack = user.audioTrack;
                        audioTrack.play();
                    }
                });
                this.isOriginalPlaying = true;
            }
        },
        async startSpeaking() {
            this.isLoading = true;
            if (this.recordingStatus === 'ended') {
                return;
            }

            try {
                const isPublisher = true;
                const channelname = this.ownStreamCode.toString();
                const response = await this.generateRtcToken({ channelName: channelname, isPublisher: isPublisher }).catch((error) => { console.error('Error:', error.message); });
                await this.speakingClient.join(response.data.appId, channelname, response.data.token, response.data.uid);

                this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                await this.speakingClient.publish([this.localAudioTrack]);
                await this.setMuted(false);
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async stopSpeaking() {
            await this.setMuted(true);
            this.speakingClient.leave();
            this.localAudioTrack.close();
        },
    },
    async onUnmounted() {
        await this.listeningClient.leave();
        await this.speakingClient.leave();
        if (this.localAudioTrack) {
            this.localAudioTrack.close();
        }
    },
};
</script>

<style>
/* Mobile Geräte */
@media (max-width: 599px) {
    .speaker-page-component {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }

    .speaker-page-component .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        overflow: auto;
    }

    .speaker-page-component .header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    .speaker-page-component .text-normal {
        color: #000000;
        font-family: "Roboto-Regular", Helvetica;
        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        text-align: center;
    }

    .speaker-page-component .playing-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .speaker-page-component .footer {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .speaker-page-component .text-wrapper-4 {
        color: #000000;
        font-family: "Inter-Light", Helvetica;
        font-size: 10px;
        font-weight: 300;
        margin-bottom: 5px;
    }

    .speaker-page-component .logo-mit-schrift-top {
        height: 61px;
        width: 279px;
        margin-bottom: 2rem;
    }

    .speaker-page-component .logo-mit-schrift {
        height: 33px;
        width: 159px;
    }

    .speaker-page-component .listen-speak-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 10px;
    }

    .speaker-page-component .icons {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

/* Tablet und Desktop Geräte */
@media (min-width: 600px) {
    .speaker-page-component {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }

    .speaker-page-component .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        overflow: auto;
    }

    .speaker-page-component .header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .speaker-page-component .text-normal {
        color: #000000;
        font-family: "Roboto-Regular", Helvetica;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        text-align: center;
    }

    .speaker-page-component .playing-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .speaker-page-component .footer {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .speaker-page-component .text-wrapper-4 {
        color: #000000;
        font-family: "Inter-Light", Helvetica;
        font-size: 10px;
        font-weight: 300;
        margin-bottom: 5px;
    }

    .speaker-page-component .logo-mit-schrift-top {
        height: 61px;
        width: 279px;
        margin-bottom: 2rem;
    }

    .speaker-page-component .logo-mit-schrift {
        height: 33px;
        width: 159px;
    }

    .speaker-page-component .listen-speak-wrapper {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 10px;
    }

    .speaker-page-component .icons {
        height: 35px;
        width: 35px;
        margin-right: 10px;
    }

}
</style>