const languages_detailed = [
    { name: "Afrikaans", real_name: "Afrikaans", code: "af" },
    { name: "Arabic", real_name: "\u0639\u0631\u0628\u064a", code: "ar" },
    { name: "Armenian", real_name: "\u0570\u0561\u0575\u0565\u0580\u0565\u0576", code: "hy" },
    { name: "Azerbaijani", real_name: "Az\u0259rbaycan", code: "az" },
    { name: "Belarusian", real_name: "\u0431\u0435\u043b\u0430\u0440\u0443\u0441\u043a\u0456", code: "be" },
    { name: "Bosnian", real_name: "bosanski", code: "bs" },
    { name: "Bulgarian", real_name: "\u0431\u044a\u043b\u0433\u0430\u0440\u0441\u043a\u0438", code: "bg" },
    { name: "Catalan", real_name: "catal\u00e0", code: "ca" },
    { name: "Chinese", real_name: "\u4e2d\u56fd\u4eba", code: "zh" },
    { name: "Croatian", real_name: "Hrvatski", code: "hr" },
    { name: "Czech", real_name: "\u010de\u0161tina", code: "cs" },
    { name: "Danish", real_name: "dansk", code: "da" },
    { name: "Dutch", real_name: "Nederlands", code: "nl" },
    { name: "English", real_name: "English", code: "en" },
    { name: "Estonian", real_name: "eesti keel", code: "et" },
    { name: "Finnish", real_name: "Suomalainen", code: "fi" },
    { name: "French", real_name: "Fran\u00e7ais", code: "fr" },
    { name: "Galician", real_name: "galego", code: "gl" },
    { name: "German", real_name: "Deutsch", code: "de" },
    { name: "Greek", real_name: "\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac", code: "el" },
    { name: "Hebrew", real_name: "\u05e2\u05b4\u05d1\u05e8\u05b4\u05d9\u05ea", code: "he" },
    { name: "Hindi", real_name: "\u0928\u0939\u0940\u0902", code: "hi" },
    { name: "Hungarian", real_name: "Magyar", code: "hu" },
    { name: "Icelandic", real_name: "\u00edslenskur", code: "is" },
    { name: "Indonesian", real_name: "bahasa Indonesia", code: "id" },
    { name: "Italian", real_name: "Italiano", code: "it" },
    { name: "Japanese", real_name: "\u65e5\u672c\u8a9e", code: "ja" },
    { name: "Kannada", real_name: "\u0c95\u0ca8\u0ccd\u0ca8\u0ca1", code: "kn" },
    { name: "Kazakh", real_name: "\u049b\u0430\u0437\u0430\u049b", code: "kk" },
    { name: "Korean", real_name: "\ud55c\uad6d\uc778", code: "ko" },
    { name: "Latvian", real_name: "latviski", code: "lv" },
    { name: "Lithuanian", real_name: "lietuvi\u0173", code: "lt" },
    { name: "Macedonian", real_name: "\u043c\u0430\u043a\u0435\u0434\u043e\u043d\u0441\u043a\u0438", code: "mk" },
    { name: "Malay", real_name: "Melayu", code: "ms" },
    { name: "Marathi", real_name: "\u092e\u0930\u093e\u0920\u0940", code: "mr" },
    { name: "Maori", real_name: "Maori", code: "mi" },
    { name: "Nepali", real_name: "\u0928\u0947\u092a\u093e\u0932\u0940", code: "ne" },
    { name: "Norwegian", real_name: "norsk", code: "no" },
    { name: "Persian", real_name: "\u0641\u0627\u0631\u0633\u06cc", code: "fa" },
    { name: "Polish", real_name: "Polski", code: "pl" },
    { name: "Portuguese", real_name: "Portugu\u00eas", code: "pt" },
    { name: "Romanian", real_name: "Rom\u00e2n\u0103", code: "ro" },
    { name: "Russian", real_name: "\u0420\u0443\u0441\u0441\u043a\u0438\u0439", code: "ru" },
    { name: "Serbian", real_name: "\u0421\u0440\u043f\u0441\u043a\u0438", code: "sr" },
    { name: "Slovak", real_name: "slovensk\u00fd", code: "sk" },
    { name: "Slovenian", real_name: "Sloven\u0161\u010dina", code: "sl" },
    { name: "Spanish", real_name: "Espa\u00f1ol", code: "es" },
    { name: "Swahili", real_name: "kiswahili", code: "sw" },
    { name: "Swedish", real_name: "svenska", code: "sv" },
    { name: "Tagalog", real_name: "Tagalog", code: "tl" },
    { name: "Tamil", real_name: "\u0ba4\u0bae\u0bbf\u0bb4\u0bcd", code: "ta" },
    { name: "Thai", real_name: "\u0e41\u0e1a\u0e1a\u0e44\u0e17\u0e22", code: "th" },
    { name: "Turkish", real_name: "T\u00fcrk\u00e7e", code: "tr" },
    { name: "Ukrainian", real_name: "\u0443\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430", code: "uk" },
    { name: "Urdu", real_name: "\u0627\u0631\u062f\u0648", code: "ur" },
    { name: "Vietnamese", real_name: "Ti\u1ebfng Vi\u1ec7t", code: "vi" },
    { name: "Welsh", real_name: "Cymraeg", code: "cy" },
];

const azure_speech_languages_extended = [
    { name: "Afrikaans (South Africa)", real_name: "Afrikaans (Suid-Afrika)", code: "af-ZA" },
    { name: "Amharic (Ethiopia)", real_name: "አማርኛ (ኢትዮጵያ)", code: "am-ET" },
    { name: "Arabic (United Arab Emirates)", real_name: "العربية (الإمارات العربية المتحدة)", code: "ar-AE" },
    { name: "Arabic (Bahrain)", real_name: "العربية (البحرين)", code: "ar-BH" },
    { name: "Arabic (Algeria)", real_name: "العربية (الجزائر)", code: "ar-DZ" },
    { name: "Arabic (Egypt)", real_name: "العربية (مصر)", code: "ar-EG" },
    { name: "Arabic (Israel)", real_name: "العربية (إسرائيل)", code: "ar-IL" },
    { name: "Arabic (Iraq)", real_name: "العربية (العراق)", code: "ar-IQ" },
    { name: "Arabic (Jordan)", real_name: "العربية (الأردن)", code: "ar-JO" },
    { name: "Arabic (Kuwait)", real_name: "العربية (الكويت)", code: "ar-KW" },
    { name: "Arabic (Lebanon)", real_name: "العربية (لبنان)", code: "ar-LB" },
    { name: "Arabic (Libya)", real_name: "العربية (ليبيا)", code: "ar-LY" },
    { name: "Arabic (Morocco)", real_name: "العربية (المغرب)", code: "ar-MA" },
    { name: "Arabic (Oman)", real_name: "العربية (عمان)", code: "ar-OM" },
    { name: "Arabic (Palestinian Authority)", real_name: "العربية (السلطة الفلسطينية)", code: "ar-PS" },
    { name: "Arabic (Qatar)", real_name: "العربية (قطر)", code: "ar-QA" },
    { name: "Arabic (Saudi Arabia)", real_name: "العربية (المملكة العربية السعودية)", code: "ar-SA" },
    { name: "Arabic (Syria)", real_name: "العربية (سوريا)", code: "ar-SY" },
    { name: "Arabic (Tunisia)", real_name: "العربية (تونس)", code: "ar-TN" },
    { name: "Arabic (Yemen)", real_name: "العربية (اليمن)", code: "ar-YE" },
    { name: "Azerbaijani (Latin, Azerbaijan)", real_name: "Azərbaycan (Latın, Azərbaycan)", code: "az-AZ" },
    { name: "Bulgarian (Bulgaria)", real_name: "български (България)", code: "bg-BG" },
    { name: "Bengali (India)", real_name: "বাংলা (ভারত)", code: "bn-IN" },
    { name: "Bosnian (Bosnia and Herzegovina)", real_name: "bosanski (Bosna i Hercegovina)", code: "bs-BA" },
    { name: "Catalan", real_name: "català", code: "ca-ES" },
    { name: "Czech (Czechia)", real_name: "čeština (Česko)", code: "cs-CZ" },
    { name: "Welsh (United Kingdom)", real_name: "Cymraeg (Y Deyrnas Unedig)", code: "cy-GB" },
    { name: "Danish (Denmark)", real_name: "dansk (Danmark)", code: "da-DK" },
    { name: "German (Austria)", real_name: "Deutsch (Österreich)", code: "de-AT" },
    { name: "German (Switzerland)", real_name: "Deutsch (Schweiz)", code: "de-CH" },
    { name: "German (Germany)", real_name: "Deutsch (Deutschland)", code: "de-DE" },
    { name: "Greek (Greece)", real_name: "Ελληνικά (Ελλάδα)", code: "el-GR" },
    { name: "English (Australia)", real_name: "English (Australia)", code: "en-AU" },
    { name: "English (Canada)", real_name: "English (Canada)", code: "en-CA" },
    { name: "English (United Kingdom)", real_name: "English (United Kingdom)", code: "en-GB" },
    { name: "English (Ghana)", real_name: "English (Ghana)", code: "en-GH" },
    { name: "English (Hong Kong SAR)", real_name: "English (Hong Kong SAR)", code: "en-HK" },
    { name: "English (Ireland)", real_name: "English (Ireland)", code: "en-IE" },
    { name: "English (India)", real_name: "English (India)", code: "en-IN" },
    { name: "English (Kenya)", real_name: "English (Kenya)", code: "en-KE" },
    { name: "English (Nigeria)", real_name: "English (Nigeria)", code: "en-NG" },
    { name: "English (New Zealand)", real_name: "English (New Zealand)", code: "en-NZ" },
    { name: "English (Philippines)", real_name: "English (Philippines)", code: "en-PH" },
    { name: "English (Singapore)", real_name: "English (Singapore)", code: "en-SG" },
    { name: "English (Tanzania)", real_name: "English (Tanzania)", code: "en-TZ" },
    { name: "English (United States)", real_name: "English (United States)", code: "en-US" },
    { name: "English (South Africa)", real_name: "English (South Africa)", code: "en-ZA" },
    { name: "Spanish (Argentina)", real_name: "Español (Argentina)", code: "es-AR" },
    { name: "Spanish (Bolivia)", real_name: "Español (Bolivia)", code: "es-BO" },
    { name: "Spanish (Chile)", real_name: "Español (Chile)", code: "es-CL" },
    { name: "Spanish (Colombia)", real_name: "Español (Colombia)", code: "es-CO" },
    { name: "Spanish (Costa Rica)", real_name: "Español (Costa Rica)", code: "es-CR" },
    { name: "Spanish (Cuba)", real_name: "Español (Cuba)", code: "es-CU" },
    { name: "Spanish (Dominican Republic)", real_name: "Español (República Dominicana)", code: "es-DO" },
    { name: "Spanish (Ecuador)", real_name: "Español (Ecuador)", code: "es-EC" },
    { name: "Spanish (Spain)", real_name: "Español (España)", code: "es-ES" },
    { name: "Spanish (Equatorial Guinea)", real_name: "Español (Guinea Ecuatorial)", code: "es-GQ" },
    { name: "Spanish (Guatemala)", real_name: "Español (Guatemala)", code: "es-GT" },
    { name: "Spanish (Honduras)", real_name: "Español (Honduras)", code: "es-HN" },
    { name: "Spanish (Mexico)", real_name: "Español (México)", code: "es-MX" },
    { name: "Spanish (Nicaragua)", real_name: "Español (Nicaragua)", code: "es-NI" },
    { name: "Spanish (Panama)", real_name: "Español (Panamá)", code: "es-PA" },
    { name: "Spanish (Peru)", real_name: "Español (Perú)", code: "es-PE" },
    { name: "Spanish (Puerto Rico)", real_name: "Español (Puerto Rico)", code: "es-PR" },
    { name: "Spanish (Paraguay)", real_name: "Español (Paraguay)", code: "es-PY" },
    { name: "Spanish (El Salvador)", real_name: "Español (El Salvador)", code: "es-SV" },
    { name: "Spanish (United States)", real_name: "Español (Estados Unidos)", code: "es-US" },
    { name: "Spanish (Uruguay)", real_name: "Español (Uruguay)", code: "es-UY" },
    { name: "Spanish (Venezuela)", real_name: "Español (Venezuela)", code: "es-VE" },
    { name: "Estonian (Estonia)", real_name: "eesti (Eesti)", code: "et-EE" },
    { name: "Basque", real_name: "euskara", code: "eu-ES" },
    { name: "Persian (Iran)", real_name: "فارسی (ایران)", code: "fa-IR" },
    { name: "Finnish (Finland)", real_name: "suomi (Suomi)", code: "fi-FI" },
    { name: "Filipino (Philippines)", real_name: "Filipino (Pilipinas)", code: "fil-PH" },
    { name: "French (Belgium)", real_name: "Français (Belgique)", code: "fr-BE" },
    { name: "French (Canada)", real_name: "Français (Canada)", code: "fr-CA" },
    { name: "French (Switzerland)", real_name: "Français (Suisse)", code: "fr-CH" },
    { name: "French (France)", real_name: "Français (France)", code: "fr-FR" },
    { name: "Irish (Ireland)", real_name: "Gaeilge (Éire)", code: "ga-IE" },
    { name: "Galician", real_name: "galego", code: "gl-ES" },
    { name: "Gujarati (India)", real_name: "ગુજરાતી (ભારત)", code: "gu-IN" },
    { name: "Hebrew (Israel)", real_name: "עברית (ישראל)", code: "he-IL" },
    { name: "Hindi (India)", real_name: "हिन्दी (भारत)", code: "hi-IN" },
    { name: "Croatian (Croatia)", real_name: "hrvatski (Hrvatska)", code: "hr-HR" },
    { name: "Hungarian (Hungary)", real_name: "magyar (Magyarország)", code: "hu-HU" },
    { name: "Armenian (Armenia)", real_name: "հայերեն (Հայաստան)", code: "hy-AM" },
    { name: "Indonesian (Indonesia)", real_name: "Indonesia (Indonesia)", code: "id-ID" },
    { name: "Icelandic (Iceland)", real_name: "íslenska (Ísland)", code: "is-IS" },
    { name: "Italian (Switzerland)", real_name: "Italiano (Svizzera)", code: "it-CH" },
    { name: "Italian (Italy)", real_name: "Italiano (Italia)", code: "it-IT" },
    { name: "Japanese (Japan)", real_name: "日本語 (日本)", code: "ja-JP" },
    { name: "Javanese (Latin, Indonesia)", real_name: "Jawa (Latin, Indonesia)", code: "jv-ID" },
    { name: "Georgian (Georgia)", real_name: "ქართული (საქართველო)", code: "ka-GE" },
    { name: "Kazakh (Kazakhstan)", real_name: "қазақ тілі (Қазақстан)", code: "kk-KZ" },
    { name: "Khmer (Cambodia)", real_name: "ខ្មែរ (កម្ពុជា)", code: "km-KH" },
    { name: "Kannada (India)", real_name: "ಕನ್ನಡ (ಭಾರತ)", code: "kn-IN" },
    { name: "Korean (Korea)", real_name: "한국어 (대한민국)", code: "ko-KR" },
    { name: "Lao (Laos)", real_name: "ລາວ (ລາວ)", code: "lo-LA" },
    { name: "Lithuanian (Lithuania)", real_name: "lietuvių (Lietuva)", code: "lt-LT" },
    { name: "Latvian (Latvia)", real_name: "latviešu (Latvija)", code: "lv-LV" },
    { name: "Macedonian (North Macedonia)", real_name: "македонски (Северна Македонија)", code: "mk-MK" },
    { name: "Malayalam (India)", real_name: "മലയാളം (ഇന്ത്യ)", code: "ml-IN" },
    { name: "Mongolian (Mongolia)", real_name: "монгол (Монгол)", code: "mn-MN" },
    { name: "Marathi (India)", real_name: "मराठी (भारत)", code: "mr-IN" },
    { name: "Malay (Malaysia)", real_name: "Melayu (Malaysia)", code: "ms-MY" },
    { name: "Maltese (Malta)", real_name: "Malti (Malta)", code: "mt-MT" },
    { name: "Burmese (Myanmar)", real_name: "မြန်မာ (မြန်မာ)", code: "my-MM" },
    { name: "Norwegian Bokmål (Norway)", real_name: "norsk bokmål (Norge)", code: "nb-NO" },
    { name: "Nepali (Nepal)", real_name: "नेपाली (नेपाल)", code: "ne-NP" },
    { name: "Dutch (Belgium)", real_name: "Nederlands (België)", code: "nl-BE" },
    { name: "Dutch (Netherlands)", real_name: "Nederlands (Nederland)", code: "nl-NL" },
    { name: "Punjabi (India)", real_name: "ਪੰਜਾਬੀ (ਭਾਰਤ)", code: "pa-IN" },
    { name: "Polish (Poland)", real_name: "polski (Polska)", code: "pl-PL" },
    { name: "Pashto (Afghanistan)", real_name: "پښتو (افغانستان)", code: "ps-AF" },
    { name: "Portuguese (Brazil)", real_name: "Português (Brasil)", code: "pt-BR" },
    { name: "Portuguese (Portugal)", real_name: "Português (Portugal)", code: "pt-PT" },
    { name: "Romanian (Romania)", real_name: "română (România)", code: "ro-RO" },
    { name: "Russian (Russia)", real_name: "русский (Россия)", code: "ru-RU" },
    { name: "Sinhala (Sri Lanka)", real_name: "සිංහල (ශ්රී ලංකාව)", code: "si-LK" },
    { name: "Slovak (Slovakia)", real_name: "slovenčina (Slovensko)", code: "sk-SK" },
    { name: "Slovenian (Slovenia)", real_name: "slovenščina (Slovenija)", code: "sl-SI" },
    { name: "Somali (Somalia)", real_name: "Soomaali (Soomaaliya)", code: "so-SO" },
    { name: "Albanian (Albania)", real_name: "shqip (Shqipëri)", code: "sq-AL" },
    { name: "Serbian (Cyrillic, Serbia)", real_name: "српски (Србија)", code: "sr-RS" },
    { name: "Swedish (Sweden)", real_name: "svenska (Sverige)", code: "sv-SE" },
    { name: "Kiswahili (Kenya)", real_name: "Kiswahili (Kenya)", code: "sw-KE" },
    { name: "Kiswahili (Tanzania)", real_name: "Kiswahili (Tanzania)", code: "sw-TZ" },
    { name: "Tamil (India)", real_name: "தமிழ் (இந்தியா)", code: "ta-IN" },
    { name: "Telugu (India)", real_name: "తెలుగు (భారతదేశం)", code: "te-IN" },
    { name: "Thai (Thailand)", real_name: "ไทย (ประเทศไทย)", code: "th-TH" },
    { name: "Turkish (Türkiye)", real_name: "Türkçe (Türkiye)", code: "tr-TR" },
    { name: "Ukrainian (Ukraine)", real_name: "українська (Україна)", code: "uk-UA" },
    { name: "Urdu (India)", real_name: "اردو (بھارت)", code: "ur-IN" },
    { name: "Uzbek (Latin, Uzbekistan)", real_name: "o'zbek (lotin, O'zbekiston)", code: "uz-UZ" },
    { name: "Vietnamese (Vietnam)", real_name: "Tiếng Việt (Việt Nam)", code: "vi-VN" },
    { name: "Chinese (Wu, Simplified)", real_name: "吴语 (简体)", code: "wuu-CN" },
    { name: "Chinese (Cantonese, Simplified)", real_name: "粤语 (简体)", code: "yue-CN" },
    { name: "Chinese (Mandarin, Simplified)", real_name: "普通话 (简体)", code: "zh-CN" },
    { name: "Chinese (Jilu Mandarin, Simplified)", real_name: "冀鲁官话 (简体)", code: "zh-CN-shandong" },
    { name: "Chinese (Southwestern Mandarin, Simplified)", real_name: "西南官话 (简体)", code: "zh-CN-sichuan" },
    { name: "Chinese (Cantonese, Traditional)", real_name: "粵語 (繁體)", code: "zh-HK" },
    { name: "Chinese (Taiwanese Mandarin, Traditional)", real_name: "國語 (繁體)", code: "zh-TW" },
    { name: "isiZulu (South Africa)", real_name: "isiZulu (iNingizimu Afrika)", code: "zu-ZA" }
];

const azure_speech_languages = [
    { name: "Afrikaans", real_name: "Afrikaans", code: "af-ZA" },
    { name: "Amharic", real_name: "አማርኛ", code: "am-ET" },
    { name: "Arabic", real_name: "العربية", code: "ar-AE" },
    { name: "Azerbaijani", real_name: "Azərbaycan", code: "az-AZ" },
    { name: "Bulgarian", real_name: "български", code: "bg-BG" },
    { name: "Bengali", real_name: "বাংলা", code: "bn-IN" },
    { name: "Bosnian", real_name: "bosanski", code: "bs-BA" },
    { name: "Catalan", real_name: "català", code: "ca-ES" },
    { name: "Czech", real_name: "čeština", code: "cs-CZ" },
    { name: "Welsh", real_name: "Cymraeg", code: "cy-GB" },
    { name: "Danish", real_name: "dansk", code: "da-DK" },
    { name: "German", real_name: "Deutsch", code: "de-DE" },
    { name: "Greek", real_name: "Ελληνικά", code: "el-GR" },
    { name: "English", real_name: "English", code: "en-US" },
    { name: "Spanish", real_name: "Español", code: "es-ES" },
    { name: "Estonian", real_name: "eesti keel", code: "et-EE" },
    { name: "Basque", real_name: "euskara", code: "eu-ES" },
    { name: "Persian", real_name: "فارسی", code: "fa-IR" },
    { name: "Finnish", real_name: "suomi", code: "fi-FI" },
    { name: "Filipino", real_name: "Filipino", code: "fil-PH" },
    { name: "French", real_name: "Français", code: "fr-FR" },
    { name: "Irish", real_name: "Gaeilge", code: "ga-IE" },
    { name: "Galician", real_name: "galego", code: "gl-ES" },
    { name: "Gujarati", real_name: "ગુજરાતી", code: "gu-IN" },
    { name: "Hebrew", real_name: "עברית", code: "he-IL" },
    { name: "Hindi", real_name: "हिन्दी", code: "hi-IN" },
    { name: "Croatian", real_name: "hrvatski", code: "hr-HR" },
    { name: "Hungarian", real_name: "magyar", code: "hu-HU" },
    { name: "Armenian", real_name: "հայերեն", code: "hy-AM" },
    { name: "Indonesian", real_name: "Indonesia", code: "id-ID" },
    { name: "Icelandic", real_name: "íslenska", code: "is-IS" },
    { name: "Italian", real_name: "Italiano", code: "it-IT" },
    { name: "Japanese", real_name: "日本語", code: "ja-JP" },
    { name: "Javanese", real_name: "Jawa", code: "jv-ID" },
    { name: "Georgian", real_name: "ქართული", code: "ka-GE" },
    { name: "Kazakh", real_name: "қазақ тілі", code: "kk-KZ" },
    { name: "Khmer", real_name: "ខ្មែរ", code: "km-KH" },
    { name: "Kannada", real_name: "ಕನ್ನಡ", code: "kn-IN" },
    { name: "Korean", real_name: "한국어", code: "ko-KR" },
    { name: "Lao", real_name: "ລາວ", code: "lo-LA" },
    { name: "Lithuanian", real_name: "lietuvių", code: "lt-LT" },
    { name: "Latvian", real_name: "latviešu", code: "lv-LV" },
    { name: "Macedonian", real_name: "македонски", code: "mk-MK" },
    { name: "Malayalam", real_name: "മലയാളം", code: "ml-IN" },
    { name: "Mongolian", real_name: "монгол", code: "mn-MN" },
    { name: "Marathi", real_name: "मराठी", code: "mr-IN" },
    { name: "Malay", real_name: "Melayu", code: "ms-MY" },
    { name: "Maltese", real_name: "Malti", code: "mt-MT" },
    { name: "Burmese", real_name: "မြန်မာ", code: "my-MM" },
    { name: "Norwegian", real_name: "norsk bokmål", code: "nb-NO" },
    { name: "Nepali", real_name: "नेपाली", code: "ne-NP" },
    { name: "Dutch", real_name: "Nederlands", code: "nl-NL" },
    { name: "Punjabi", real_name: "ਪੰਜਾਬੀ", code: "pa-IN" },
    { name: "Polish", real_name: "polski", code: "pl-PL" },
    { name: "Pashto", real_name: "پښتو", code: "ps-AF" },
    { name: "Portuguese", real_name: "Português", code: "pt-BR" },
    { name: "Romanian", real_name: "română", code: "ro-RO" },
    { name: "Russian", real_name: "русский", code: "ru-RU" },
    { name: "Sinhala", real_name: "සිංහල", code: "si-LK" },
    { name: "Slovak", real_name: "slovenčina", code: "sk-SK" },
    { name: "Slovenian", real_name: "slovenščina", code: "sl-SI" },
    { name: "Somali", real_name: "Soomaali", code: "so-SO" },
    { name: "Albanian", real_name: "shqip", code: "sq-AL" },
    { name: "Serbian", real_name: "српски", code: "sr-RS" },
    { name: "Swedish", real_name: "svenska", code: "sv-SE" },
    { name: "Swahili", real_name: "Kiswahili", code: "sw-KE" },
    { name: "Tamil", real_name: "தமிழ்", code: "ta-IN" },
    { name: "Telugu", real_name: "తెలుగు", code: "te-IN" },
    { name: "Thai", real_name: "ไทย", code: "th-TH" },
    { name: "Turkish", real_name: "Türkçe", code: "tr-TR" },
    { name: "Ukrainian", real_name: "українська", code: "uk-UA" },
    { name: "Urdu", real_name: "اردو", code: "ur-IN" },
    { name: "Uzbek", real_name: "o'zbek", code: "uz-UZ" },
    { name: "Vietnamese", real_name: "Tiếng Việt", code: "vi-VN" },
    { name: "Chinese (Wu)", real_name: "吴语", code: "wuu-CN" },
    { name: "Chinese (Cantonese)", real_name: "粵語", code: "yue-CN" },
    { name: "Chinese (Mandarin)", real_name: "普通话", code: "zh-CN" },
    { name: "Zulu", real_name: "isiZulu", code: "zu-ZA" }
];

export { languages_detailed, azure_speech_languages, azure_speech_languages_extended };