<template>
  <div class="admin-language-button-component border-[1px] rounded-xl flex items-center relative h-[75px] w-[1000px]"
    :class="borderColor">
    <div class="checkbox-container">
      <input type="checkbox" :checked="isChecked" class="w-[22px] h-[22px] border-[1px] border-slate-400 rounded-[4px]"
        @change="$emit('checkboxChanged', $event.target.checked)" :disabled="missingInformations || !editedName" />
    </div>
    <input type="text" class="text-wrapper" @input="handleInput" :placeholder="$t('channelName')" v-model="editedName" :autofocus="!this.editedName.length" />
    <div class="absolute h-[30px] w-[200px] left-[280px] border-[1px] cursor-pointer border-slate-400 rounded-lg"
      :class="{ 'opacity-60': isTranslationRunning }" @click="toggleSection">
      <div class="selected-option">{{ currentOption }}</div>
      <v-img :src="require('@/assets/rotate.svg')" class="w-[24px] h-[24px] mt-[2px] absolute left-[167px]"></v-img>
      <v-tooltip v-if="!this.$store.state.planActive" activator="parent" location="bottom">
        {{ $t('activatePlanForInterpreter') }}
      </v-tooltip>
    </div>
    <div class="absolute top-[26px] left-[560px] text-blue-800 text-blue-800 cursor-pointer" :class="textColor"
      @click="$emit('shareClicked')" v-if="propsCurrentOption !== 'Nutze KI'">{{ $t('shareInterpreterUrl') }}</div>
    <div v-else>
      <div class="absolute top-[5px] left-[560px] w-[250px] h-[10px] text-rose-500 cursor-pointer"
        v-if="!propsTargetLanguage">
        <v-autocomplete v-model="selectedTargetLanguage" :items="allLanguages" :label="$t('selectTargetLanguage')"
          item-title="combined_name" item-value="code" return-object variant="plain">
        </v-autocomplete>
      </div>
      <div class="absolute top-[26px] left-[560px] text-blue-800 text-blue-800 cursor-pointer" v-else
        @click="selectedTargetLanguage = null">
        {{ $t('targetLanguage') }} {{ displayName }}</div>
    </div>
    <div class="absolute top-[24px] left-[850px] text-grey">{{ $t('listeners') }} {{ currentListeners }}</div>
    <img class="absolute w-[22px] left-[955px] cursor-pointer" alt="Window close" :src="windowClose"
      @click="$emit('closeClicked')" />

    <v-tooltip v-if="showTooltip" activator="parent" location="top">
      {{ toolTipText }}
    </v-tooltip>
  </div>
</template>

<script>
import windowClose from '@/assets/window-close.svg';
import rotate from '@/assets/rotate.svg';
import down from '@/assets/down.svg';
import up from '@/assets/up.svg';
import { languages_detailed } from '../languages_detailed';

export default {
  name: 'AdminLanguageButton',
  props: {
    propsIsTranslationRunning: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsEditedName: {
      type: String,
      default: '',
      required: true,
    },
    propsIsChecked: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsCurrentOption: {
      type: String,
      default: 'Nutze KI',
      required: true,
    },
    propsTargetLanguage: {
      type: Object,
      default: null,
    },
    propsCurrentListeners: {
      type: Number,
      default: 0,
    },
    propsIsOriginalLanguageSet: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      windowClose: windowClose,
      down: down,
      up: up,
      rotate: rotate,
      isDropdownOpen: false,
      editedName: this.propsEditedName,
      selectedTargetLanguage: this.propsTargetLanguage,
      allLanguages: [],
      isChecked: this.propsIsChecked,
      currentListeners: this.propsCurrentListeners,
      isOriginalLanguageSet: this.propsIsOriginalLanguageSet,
      isTranslationRunning: this.propsIsTranslationRunning,
    };
  },
  created() {
    this.allLanguages = languages_detailed.map(lang => {
      return {
        code: lang.code,
        name: lang.name,
        real_name: lang.real_name,
        combined_name: lang.name + ' (' + lang.real_name + ')',
      };
    });
  },
  watch: {
    propsCurrentListeners(newValue) {
      this.currentListeners = newValue;
    },
    propsEditedName(newValue) {
      this.editedName = newValue;
    },
    propsTargetLanguage(newValue) {
      this.selectedTargetLanguage = newValue;
    },
    propsIsChecked(newValue) {
      this.isChecked = newValue;
    },
    propsIsOriginalLanguageSet(newValue) {
      this.isOriginalLanguageSet = newValue;
    },
    propsIsTranslationRunning(newValue) {
      this.isTranslationRunning = newValue;
    },
    selectedTargetLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('targetlanguageSelected', newVal);
      }
    },
    missingInformations(newValue) {
      if (newValue) {
        this.isChecked = false;
        this.$emit('checkboxChanged', false);
      } else {
        this.isChecked = this.propsIsChecked;
      }
    },
  },
  computed: {
    currentOption() {
      if (this.propsCurrentOption !== 'Nutze KI') {
        return this.$t('useInterpreter');
      }
      return this.$t('useAI');
    },
    showTooltip() {
      return !this.editedName.length || this.missingInformations || !this.isOriginalLanguageSet;
    },
    toolTipText() {
      if (!this.editedName.length) return this.$t('tooltip.fillInChannelname');
      if (!this.isOriginalLanguageSet) return this.$t('tooltip.selectSourceLanguage');
      if (this.missingInformations) return this.$t('tooltip.selectTargetLanguage');
      return this.$t('tooltip.fillInAllFields');
    },
    borderColor() {
      return this.missingInformations ? 'border-rose-500' : 'border-slate-400';
    },
    textColor() {
      return this.missingInformations ? 'text-rose-500' : 'text-blue-800';
    },
    displayName() {
      return this.selectedTargetLanguage ? this.selectedTargetLanguage.name : this.$t('select');
    },
    missingInformations() {
      return this.editedName.length && this.propsCurrentOption === 'Nutze KI' && (!this.selectedTargetLanguage || !this.isOriginalLanguageSet);
    },
    useAutoFocus() {
      return !this.editedName.length;
    },
  },
  methods: {
    toggleSection() {
      if (this.isTranslationRunning) return;
      if (this.$store.state.planActive) {
        this.$emit('toggleSelection')
      }
    },
    handleInput(event) {
      const inputValue = event.target.value;
      if (inputValue.length <= 12) {
        this.editedName = inputValue;
        this.$emit('nameChanged', inputValue)
      } else {
        this.editedName = inputValue.slice(0, 13);
      }
    },
    toggleIcon() {
      if (this.isDropdownOpen) {
        return up;
      }
      return down;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.$emit('optionSelected', option);
    },
  },
};
</script>


<style>
.admin-language-button-component .rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: 4px;
  height: 22px;
  left: 25px;
  position: absolute;
  top: 25px;
  width: 22px;
}

.admin-language-button-component .text-wrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.admin-language-button-component .overlap-group {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: 6px;
  height: 30px;
  left: 355px;
  position: absolute;
  top: 22px;
  width: 200px;
}

.admin-language-button-component .selected-option {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.admin-language-button-component .down {
  height: 24px;
  left: 167px;
  position: absolute;
  top: 2px;
  width: 24px;
}

.admin-language-button-component .text-wrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  outline: none;
}

.admin-language-button-component .checkbox-container {
  height: 32px;
  /* Angepasst für äußeren Container */
  left: 25px;
  position: absolute;
  top: 20px;
  width: 32px;
  /* Angepasst für äußeren Container */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>