<template>
    <div class="speaker-component">
        <div class="language-text-normal" :style="{ color: textColor }">{{ language }}</div>
        <div class="unmute-button" :style="{ backgroundColor: textColor }" @click="$emit('start')">
            <div v-if="!isLoading">
                <img class="vector" alt="Vector" :src="microphoneIcon" />
                <div class="text-wrapper-3">Start</div>
            </div>
            <div class="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white-500 mx-auto mt-2"
                v-else>
            </div>
        </div>
    </div>
</template>

<script>
import microphoneIcon from '@/assets/microphone white.svg';

export default {
    props: {
        language: {
            type: String,
            default: "",
        },
        isDeactivated: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        textColor() {
            if (this.isDeactivated) {
                return "#C2C2C2";
            } else {
                return "#339fff";
            }
        },
    },
    data() {
        return {
            microphoneIcon: microphoneIcon,
        };
    },
};
</script>


<style>
/* Tablet und Desktop Geräte */
@media (max-height: 749px) {
    .speaker-component {
        background-color: #ffffff;
        border: 1px solid;
        border-color: #c2c2c2;
        border-radius: 20px;
        height: 299px;
        position: relative;
        width: 279px;
    }

    .speaker-component .language-text-normal {
        color: #339fff;
        font-family: "Roboto-Regular", Helvetica;
        font-size: 30px;
        font-weight: 400;
        height: 46px;
        left: 78px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 40px;
        width: 122px;
    }

    .speaker-component .img {
        height: 15px;
        left: 104px;
        position: absolute;
        top: 2px;
        width: 15px;
    }

    .speaker-component .unmute-button {
        height: 35px;
        left: 77px;
        position: absolute;
        top: 200px;
        width: 125px;
        background-color: #339fff;
        border-radius: 5px;
    }

    .speaker-component .vector {
        position: absolute;
        height: 18px;
        width: 15px;
        left: 28px;
        top: 9px;
    }

    .speaker-component .text-wrapper-3 {
        color: #ffffff;
        font-family: "Roboto-Medium", Helvetica;
        font-size: 16px;
        font-weight: 600;
        height: 14px;
        left: 53px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 9px;
        white-space: nowrap;
        width: 80px;
    }
}

/* Tablet und Desktop Geräte */
@media (min-height: 750px) {
    .speaker-component {
        background-color: #ffffff;
        border: 1px solid;
        border-color: #c2c2c2;
        border-radius: 20px;
        height: 349px;
        position: relative;
        width: 279px;
    }

    .speaker-component .language-text-normal {
        color: #339fff;
        font-family: "Roboto-Regular", Helvetica;
        font-size: 30px;
        font-weight: 400;
        height: 46px;
        left: 78px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 50px;
        width: 122px;
    }

    .speaker-component .img {
        height: 15px;
        left: 104px;
        position: absolute;
        top: 2px;
        width: 15px;
    }

    .speaker-component .unmute-button {
        height: 35px;
        left: 77px;
        position: absolute;
        top: 238px;
        width: 125px;
        background-color: #339fff;
        border-radius: 5px;
    }

    .speaker-component .vector {
        position: absolute;
        height: 18px;
        width: 15px;
        left: 28px;
        top: 9px;
    }

    .speaker-component .text-wrapper-3 {
        color: #ffffff;
        font-family: "Roboto-Medium", Helvetica;
        font-size: 16px;
        font-weight: 600;
        height: 14px;
        left: 53px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 9px;
        white-space: nowrap;
        width: 80px;
    }
}
</style>