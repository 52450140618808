<template>
  <div class="w-4/5 min-w-[500px] mx-auto grid grid-cols-1 divide-y divide-slate-300">
    <div class="text-xl ma-5 mt-10">
      <span class="text-2xl">Weitere Funktionalitäten</span> <span class="text-sm text-emerald-700">(beta)</span>
    </div>
    <div>
      <div class="container mx-auto p-4">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="flex justify-between">
            <h1 class="text-xl mb-4">Text zu Sprache</h1>
            <!-- Sprachtyp auwahl dropdown -->
            <div class="relative inline-block" ref="voiceDropdown">
              <div class="rounded-lg border px-3 py-1 w-44 text-center inline-flex items-center cursor-pointer"
                @click="isVoiceDropdownOpen = !isVoiceDropdownOpen">
                Stimme ({{ selectedVoiceLabel }})
                <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="m1 1 4 4 4-4" />
                </svg>
              </div>
              <!-- Dropdown menu -->
              <div v-if="isVoiceDropdownOpen" class="absolute left-0 mt-2 w-48 bg-white rounded-lg shadow z-10">
                <ul class="p-3 space-y-1 text-sm text-gray-700">
                  <li v-for="(voice, index) in voices" :key="index">
                    <div class="flex items-center p-2 rounded hover:bg-gray-100">
                      <input :id="'radio-' + index" type="radio" v-model="voiceForTTS" :value="voice"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" @click="selectVoice(voice)">
                      <label :for="'radio-' + index" class="w-full ms-2 text-sm font-medium text-gray-900 rounded"
                        @click="selectVoice(voice)">{{
                          voice }}</label>
                      <v-img :src="require('@/assets/waveform.svg')"
                        class="w-6 hover:bg-blue-100 rounded p-2 cursor-pointer" @click="playVoice(index)"
                        contain></v-img>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- multiple line text input -->
          <textarea v-model="textForTTS"
            placeholder="Geben Sie hier den Text ein, den Sie in Sprache umwandeln möchten."
            class="p-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            rows="4"></textarea>
          <div class="relative">
            <div class="text-sm text-grey absolute -mt-6 right-[10px]">
              {{ textForTTS.length }} Zeichen
            </div>
          </div>
          <div v-if="urlForTTS.length > 0" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Ergebnis:</h2>
            <div v-for="(url, index) in urlForTTS" :key="index">
              <audio :src="url" controls class="w-full mt-2"></audio>
            </div>
          </div>
          <div>
            <button @click="doTTS" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-[240px]">
              <div v-if="!isTTSLoading">Text zu Sprache umwandeln</div>
              <div class="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white-500 mx-auto" v-else>
              </div>
            </button>
          </div>
          <div class="relative">
            <div class="text-sm text-grey absolute right-0">
              1500 Zeichen entsprechen 1min KI Übersetzung
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto p-4">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="flex justify-between">
            <h1 class="text-xl mb-4">Live Audio Transkription</h1>
            <div class="w-[250px] -mt-3 cursor-pointer"
              :class="selectedSourceLanguageLiveTranscription ? 'text-blue-600' : 'text-rose-500'">
              <v-autocomplete v-model="selectedSourceLanguageLiveTranscription" :items="allLanguages"
                label="Originalsprache auswählen" item-title="combined_name" item-value="code" return-object
                variant="plain">
              </v-autocomplete>
            </div>
          </div>
          <!-- wenn eine transcription verfügbar ist hier anzeigen -->
          <div v-if="liveTranscription" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Ergebnis:</h2>
            <div class="p-2 border border-gray-300 rounded-lg text-sm" v-html="liveTranscription"></div>
          </div>
          <button class="mt-4 bg-blue-500 hover:bg-rose-400 text-white py-2 px-4 rounded w-[240px]"
            v-if="!selectedSourceLanguageLiveTranscription">
            <div>Bitte Sprache auswählen</div>
          </button>
          <button @click="startLiveTranscription"
            class="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-[240px]"
            v-else-if="selectedSourceLanguageLiveTranscription && !liveTranscriptionActive">
            <div>Starte Aufnahme</div>
          </button>
          <button @click="stopLiveTranslation"
            class="mt-4 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded w-[240px]"
            v-else-if="liveTranscriptionActive">
            <div>Stoppe Aufnahme</div>
          </button>
          <div class="relative mt-2">
            <div class="text-sm text-grey absolute right-0">
              3 minuten Transkription entsprechen 1min KI Übersetzung
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto p-4">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="flex justify-between">
            <h1 class="text-xl mb-4">Video/Audio Transkription</h1>
            <div class="w-[250px] -mt-3 cursor-pointer"
              :class="selectedSourceLanguage ? 'text-blue-600' : 'text-rose-500'">
              <v-autocomplete v-model="selectedSourceLanguage" :items="allLanguages" label="Originalsprache auswählen"
                item-title="combined_name" item-value="code" return-object variant="plain">
              </v-autocomplete>
            </div>
          </div>
          <input type="file" accept="video/*,audio/*" @change="handleFileUpload"
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-1" />
          <!-- wenn eine transcription verfügbar ist hier anzeigen -->
          <div v-if="transcription" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Ergebnis:</h2>
            <div class="p-2 border border-gray-300 rounded-lg text-sm">
              {{ transcription }}
            </div>
          </div>
          <div v-if="file && selectedSourceLanguage" class="mt-4">
            <button @click="transcribeFile"
              class="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-[240px]">
              <div v-if="!isSTTLoading">Sprache zu Text umwandeln</div>
              <div class="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white-500 mx-auto" v-else>
              </div>
            </button>
          </div>
          <div class="relative mt-2">
            <div class="text-sm text-grey absolute right-0">
              3 minuten Transkription entsprechen 1min KI Übersetzung
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto p-4">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="flex justify-between">
            <h1 class="text-xl mb-4">Video/Audio Übersetzung</h1>
            <div class="flex">
              <div class="w-[250px] -mt-3 cursor-pointer mr-8"
                :class="selectedSourceLanguageTranslation ? 'text-blue-600' : 'text-rose-500'">
                <v-autocomplete v-model="selectedSourceLanguageTranslation" :items="allLanguages"
                  label="Originalsprache auswählen" item-title="combined_name" item-value="code" return-object
                  variant="plain">
                </v-autocomplete>
              </div>
              <div class="w-[250px] -mt-3 cursor-pointer"
                :class="selectedTargetLanguageTranslation ? 'text-blue-600' : 'text-rose-500'">
                <v-autocomplete v-model="selectedTargetLanguageTranslation" :items="allLanguages"
                  label="Zielsprache auswählen" item-title="combined_name" item-value="code" return-object
                  variant="plain">
                </v-autocomplete>
              </div>
            </div>
          </div>
          <input type="file" accept="video/*,audio/*" @change="handleTranslationFileUpload"
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-1" />
          <!-- wenn eine transcription verfügbar ist hier anzeigen -->
          <div v-if="translation" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Ergebnis:</h2>
            <div class="p-2 border border-gray-300 rounded-lg text-sm">
              {{ translation }}
            </div>
          </div>
          <div v-if="urlForSTS.length > 0" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Ergebnis:</h2>
            <div v-for="(url, index) in urlForSTS" :key="index">
              <audio :src="url" controls class="w-full mt-2"></audio>
            </div>
          </div>
          <div v-if="translationFile && selectedSourceLanguageTranslation && selectedTargetLanguageTranslation"
            class="mt-4">
            <button @click="translateAudio"
              class="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-[240px]">
              <div v-if="!isTranslatingLoading">Audio übersetzen</div>
              <div class="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white-500 mx-auto" v-else>
              </div>
            </button>
          </div>
          <div class="relative mt-2">
            <div class="text-sm text-grey absolute right-0">
              1min Übersetzung entsprechen 1min KI Übersetzung
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { functions, storage } from '../firebaseInit';
import { httpsCallable } from 'firebase/functions';
import { languages_detailed } from '../languages_detailed';
import { ref, uploadBytes } from 'firebase/storage';

export default {
  data() {
    return {
      conferenceId: this.$store.state.conferenceId,
      file: null,
      translationFile: null,
      isVoiceDropdownOpen: false,
      isOriginalLanguageDropdownOpen: false,
      isTargetLanguagesDropdownOpen: false,
      selectedSourceLanguage: null,
      selectedSourceLanguageLiveTranscription: null,
      selectedSourceLanguageTranslation: null,
      selectedTargetLanguageTranslation: null,
      isTTSLoading: false,
      isSTTLoading: false,
      isTranslatingLoading: false,
      transcription: '',
      translation: '',
      textForTTS: '',
      voiceForTTS: 'Alloy',
      selectedVoiceLabel: 'Alloy',
      urlForTTS: [],
      urlForSTS: [],
      voices: ['Alloy', 'Echo', 'Fable', 'Onyx', 'Nova', 'Shimmer'],
      sampleVoices: [
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318062336.wav?alt=media&token=5dc3daa1-8e5a-455a-87a1-51aa6aafac59", 'isPlaying': false, "audio": null },
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318004633.wav?alt=media&token=4a70b0d8-7d63-4321-9c2b-8359cba5f1c1", 'isPlaying': false, "audio": null },
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318036663.wav?alt=media&token=2eca3c17-cd23-4e4f-ad8c-b8be4e8a3f58", 'isPlaying': false, "audio": null },
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318135227.wav?alt=media&token=7f01a277-06e9-4d07-a42a-b79850d448ce", 'isPlaying': false, "audio": null },
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318473767.wav?alt=media&token=1ea25d39-b1a1-43cf-b8a0-30ae600d9fd4", 'isPlaying': false, "audio": null },
        { "url": "https://firebasestorage.googleapis.com/v0/b/streamlingo-staging.appspot.com/o/audio%2F799977%2Ftts%2Fspeech_1716318547294.wav?alt=media&token=8cb7e66b-8938-47ed-afe6-3db956371746", 'isPlaying': false, "audio": null },
      ],
      allLanguages: [],
      liveTranscriptionActive: false,
      liveTranscription: '',
      audioChunks: [],
      mediaRecorder: null,
      recordingStatus: "running",
      latenz: 5000,
      isChrome: false,
      isliveTranscriptionLoading: false,
      textToSpeech: httpsCallable(functions, 'textToSpeech'),
      transcribeAudioAssemblyAI: httpsCallable(functions, 'transcribeAudioAssemblyAI'),
      synchronizedSpeechToSpeechTranslation: httpsCallable(functions, 'synchronizedSpeechToSpeechTranslation'),
      synchronizedVideoTranslation: httpsCallable(functions, 'synchronizedVideoTranslation'),
    };
  },
  created() {
    this.checkBrowser();
    this.textToSpeech({ reduceColdStart: true });
    this.transcribeAudioAssemblyAI({ reduceColdStart: true });
    this.synchronizedSpeechToSpeechTranslation({ reduceColdStart: true });
    this.synchronizedVideoTranslation({ reduceColdStart: true });
    this.allLanguages = languages_detailed.map(lang => {
      return {
        code: lang.code,
        name: lang.name,
        real_name: lang.real_name,
        combined_name: lang.name + ' (' + lang.real_name + ')',
      };
    });
    this.waitForConferenceId().then((conferenceId) => {
      this.conferenceId = conferenceId;
    });
  },
  methods: {
    async waitForConferenceId(timeout = 5000) {
      let timeoutId;
      const cleanup = () => { clearTimeout(timeoutId); };
      return new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => { cleanup(); reject(new Error('Timeout waiting for user data')); }, timeout); // Timeout after 5 seconds
        if (this.$store.state.conferenceId) {
          cleanup(); resolve(this.$store.state.conferenceId);
        } else {
          this.$store.watch((state) => state.conferenceId, (newValue) => {
            cleanup(); resolve(newValue);
          });
        }
      });
    },
    checkBrowser() {
      this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      if (!this.isChrome) {
        this.$store.dispatch('showSnackbar', { message: "Bitte verwenden Sie Google Chrome für die Live-Übersetzung.", timeout: -1 });
      }
    },
    async startLiveTranscription() {
      if (this.isliveTranscriptionLoading || this.liveTranscriptionActive || !this.isChrome) return;
      this.isliveTranscriptionLoading = true;
      this.liveTranscriptionActive = true;
      try {
        this.audioChunks = [];
        const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.setupLiveMediaRecorder(localStream);
        this.mediaRecorder.start(this.latenz);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isliveTranscriptionLoading = false;
      }
    },
    async stopLiveTranslation() {
      if (!this.liveTranscriptionActive) return;
      this.liveTranscriptionActive = false;
      this.mediaRecorder.stop();
    },
    setupLiveMediaRecorder(stream) {
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
        this.mediaRecorder.stop();

      };
      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
        this.sendAudioToServer(audioBlob);
        this.audioChunks = []; // Bereite für den nächsten Chunk vor
        if (this.liveTranscriptionActive) {
          setTimeout(() => this.mediaRecorder.start(this.latenz), 0);
        }
      };
    },
    async sendAudioToServer(audioBlob) {
      try {
        const transcribeLiveAudio = httpsCallable(functions, 'transcribeLiveAudio');
        const storagePath = `features/${this.conferenceId}/transcription/audio/audio_${Date.now()}.wav`;
        uploadBytes(ref(storage, storagePath), audioBlob)
          .then(() => {
            transcribeLiveAudio({
              filePath: storagePath,
              sourceLanguage: this.selectedSourceLanguageLiveTranscription.code,
              conferenceId: this.conferenceId,
              recordingStatus: this.liveTranscriptionActive ? "running" : "ended",
            })
              .then((result) => {
                if (result.data.transcription === '') return;
                if (!this.liveTranscription) {
                  this.liveTranscription = result.data.transcription;
                } else {
                  this.liveTranscription += '<br>' + result.data.transcription;
                }
              })
              .catch((error) => { console.error('Error:', error.message); });
          })
          .catch((error) => {
            console.error('Fehler beim Hochladen der Audioaufnahme:', error.message);
          });

      } catch (error) {
        console.error('Fehler beim Senden der Audioaufnahme:', error.message);
      }

    },
    translateAudio() {
      try {
        this.isTranslatingLoading = true;

        const audioBlob = this.translationFile;
        const conferenceId = this.$store.state.conferenceId;
        const storagePath = `features/${conferenceId}/translation/audio_${Date.now()}.mp4`;
        // const storagePath = `features/${conferenceId}/translation/audio_${Date.now()}.wav`;
        uploadBytes(ref(storage, storagePath), audioBlob)
          .then(() => {
            // this.synchronizedSpeechToSpeechTranslation({
            this.synchronizedVideoTranslation({
              filePath: storagePath,
              sourceLanguage: this.selectedSourceLanguageTranslation.code,
              targetLanguage: this.selectedTargetLanguageTranslation.code,
            })
              .then((result) => {
                console.log(result)
                this.urlForSTS.push(result.data.downloadUrl);
              })
              .catch((error) => { console.error('Error:', error.message); }).finally(() => {
              }).finally(() => {
                this.isTranslatingLoading = false;
              });
          })
          .catch((error) => {
            console.error('Fehler beim Hochladen der Audioaufnahme:', error.message);
          });
      } catch (error) {
        console.error('Error:', error);
      }
    },
    transcribeFile() {
      this.isSTTLoading = true;
      const audioBlob = this.file;
      const conferenceId = this.$store.state.conferenceId;
      const storagePath = `audio/${conferenceId}/stt/audio_${Date.now()}.wav`;
      uploadBytes(ref(storage, storagePath), audioBlob)
        .then(() => {
          this.transcribeAudioAssemblyAI({
            filePath: storagePath,
            sourceLanguage: this.selectedSourceLanguage.code,
          })
            .then((result) => {
              this.transcription = result.data.transcription;
            })
            .catch((error) => { console.error('Error:', error.message); }).finally(() => {
              this.isSTTLoading = false;
            });
        })
        .catch((error) => {
          console.error('Fehler beim Hochladen der Audioaufnahme:', error.message);
        });
    },
    playVoice(index) {
      if (this.sampleVoices[index].isPlaying) {
        this.sampleVoices[index].isPlaying = false;
        this.sampleVoices[index].audio.pause();
        return;
      }
      this.sampleVoices[index].audio = new Audio(this.sampleVoices[index].url);
      this.sampleVoices[index].audio.play();
      this.sampleVoices[index].isPlaying = true;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    handleTranslationFileUpload(event) {
      this.translationFile = event.target.files[0];
    },
    selectVoice(voice) {
      this.voiceForTTS = voice;
      this.selectedVoiceLabel = voice;
      this.isVoiceDropdownOpen = false;
    },
    doTTS() {
      this.isLoading = true;
      this.textToSpeech({ text: this.textForTTS, voice: this.voiceForTTS.toLocaleLowerCase(), conferenceId: this.$store.state.conferenceId })
        .then(result => {
          this.urlForTTS.push(result.data.downloadUrl);
        })
        .catch(error => {
          console.error('Error:', error);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    closeDropdown(event) {
      if (!this.$refs.voiceDropdown.contains(event.target)) {
        this.isVoiceDropdownOpen = false;
        this.isOriginalLanguageDropdownOpen = false;
        this.isTargetLanguagesDropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  },
}
</script>

<style scoped>
/* Optional custom styles */
</style>
