<template>
  <div class="login-modal">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Login</h2>
      <button @click="$emit('close')" class="text-gray-500 hover:text-gray-700">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
    <form @submit.prevent="login">
      <div class="mb-4">
        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <input v-model="email" type="email" id="email"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required>
      </div>
      <div class="mb-6">
        <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input v-model="password" :type="passwordType" id="password"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          required>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input @click="togglePasswordVisibility" id="show-password" type="checkbox"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="show-password" class="ml-2 block text-sm text-gray-900">
              Show password
            </label>
          </div>
          <div class="text-sm">
            <a @click="resetPassword" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
              Forgot your password?
            </a>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <button type="submit"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Sign In
        </button>
      </div>
    </form>
    <div class="mt-4 text-center">
      <p class="text-sm">
        Don't have an account?
        <a @click="$emit('switch-to-signup')"
          class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Sign up</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      passwordType: 'password'
    }
  },
  methods: {
    async login() {
      await this.$store.dispatch('signIn', { email: this.email, password: this.password }).then(() => {
        this.$emit('success');
      }).catch((error) => {
        this.$store.dispatch('showSnackbar', { message: error.message });
      });
    },
    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    resetPassword() {
      this.$emit('reset-password', this.email);
    }
  }
}
</script>